/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import {
  LinkIcon,
  ShareIcon,
  InboxArrowDownIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  MapPinIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
  CheckIcon
} from '@heroicons/react/20/solid'
import { XMarkIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import Chart from 'react-apexcharts'
import { Menu, Transition, Dialog, Listbox } from '@headlessui/react'
import toast, { Toaster } from 'react-hot-toast';
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';
const axios = require('axios').default;
const cookies = new Cookies();
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function Viewnbn(data) {
  const ref = useRef(null)

const [currenttab, setTab] = useState(0)
const [speedChartDataYdownload, setspeedChartDataYdownload] = useState([])
const [speedChartDataYupload, setspeedChartDataYupload] = useState([])
const [speedChartDataX, setspeedChartDataX] = useState([])

data?.data?.healthChecks?.forEach(healthcheck => {
  if(healthcheck.type == "healthCheck"){
  speedChartDataYdownload.push(healthcheck?.healthCategory[1]?.healthCategoryItem[2]?.value)
  speedChartDataYupload.push(healthcheck?.healthCategory[1]?.healthCategoryItem[3]?.value)
  speedChartDataX.push(healthcheck?.healthCategory[1]?.healthCategoryItem[2]?.timeStamp)
}
});


  let chart = {
    options: {
      chart: {
        id: 'apexchart-example',
        stacked: false
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        type: 'datetime',
        categories: speedChartDataX
      }
    },
    series: [
      {
        name: "Download",
        data: speedChartDataYdownload
      },
      {
        name: "Upload",
        data: speedChartDataYupload
      }
    ], 
  }


const serviceTests = [
  { id: 0, name: 'Health Check', code:"HEALTH_CHECK", serviceType:"all" },
  { id: 1, name: 'Loopback Test', code:"LOOPBACK", serviceType:"all" },
  { id: 2, name: 'DPU Port Status', code:"DPU_PORT_STATUS", serviceType:"FTTC" },
  { id: 3, name: 'DPU Port Reset', code:"DPU_PORT_RESET", serviceType:"FTTC" },
  { id: 4, name: 'DPU Status', code:"DPU_STATUS", serviceType:"FTTC" },
  { id: 5, name: 'Dynamic Line Management Status', code:"DYNAMIC_LINE_MANAGEMENT_STATUS", serviceType:"FTTB" },
  { id: 6, name: 'Dynamic Line Management Status', code:"DYNAMIC_LINE_MANAGEMENT_STATUS", serviceType:"FTTN" },
  { id: 7, name: 'Line Quality Diagnostic', code:"LINE_QUALITY_DIAGNOSTIC", serviceType:"FTTB" },
  { id: 8, name: 'Line Quality Diagnostic', code:"LINE_QUALITY_DIAGNOSTIC", serviceType:"FTTN" },
  { id: 9, name: 'Line State Diagnostic', code:"LINE_STATE_DIAGNOSTIC", serviceType:"FTTB" },
  { id: 10, name: 'Line State Diagnostic', code:"LINE_STATE_DIAGNOSTIC", serviceType:"FTTN" },
  { id: 11, name: 'NCD Port Reset', code:"NCD_PORT_RESET", serviceType:"FTTC" },
  { id: 12, name: 'NCD UNI-D Status', code:"NCD_UNI_D_STATUS", serviceType:"FTTC" },
  { id: 13, name: 'NCD Reset', code:"NCD_UNI_D_STATUS", serviceType:"FTTC" },
  { id: 14, name: 'NTD Reset', code:"NTD_RESET", serviceType:"HFC" },
  { id: 15, name: 'NTD Status', code:"NTD_STATUS", serviceType:"FTTP" },
  { id: 16, name: 'Port Reset', code:"PORT_RESET", serviceType:"FTTP" },
  { id: 17, name: 'Port Reset', code:"PORT_RESET", serviceType:"FTTB" },
  { id: 18, name: 'Port Reset', code:"PORT_RESET", serviceType:"FTTN" },
  { id: 19, name: 'Single End Line Test', code:"SINGLE_END_LINE_TEST", serviceType:"FTTB" },
  { id: 20, name: 'Single End Line Test', code:"SINGLE_END_LINE_TEST", serviceType:"FTTN" },
  { id: 21, name: 'NTD UNI-D Status', code:"UNI_D_STATUS", serviceType:"FTTP" },
  { id: 22, name: 'NTD Reset', code:"WNTD_RESET", serviceType:"FW" },
  { id: 23, name: 'NTD Status', code:"WNTD_STATUS", serviceType:"FW" },
]

const [selectedServiceTest, setselectedServiceTest] = useState(serviceTests[0])


const dslProfiles = [
  { id: 1, name: 'Standard' },
  { id: 2, name: 'Stable' },
]
const [selecteddslprofile, setselecteddslprofile] = useState(dslProfiles[0])

  const tabs = [
    { name: 'Service Details', tabnum: 0,},
    { name: 'Service Tests', tabnum: 1, },
    { name: 'SwiftBoost', tabnum: 2, },
    { name: 'Manage', tabnum: 3, },
  ]

  const steps = [
    { id: '1', name: 'Boost 1', description: '100/40', href: '#', },
    { id: '2', name: 'Boost 2', description: '100/40', href: '#', },
    { id: '3', name: 'Boost 3', description: '100/40', href: '#', },
  ]
  const [openservicetest, setOpenservicetest] = useState(false)
  const [servicetestboxdata, setservicetestboxdata] = useState({})


  const [runservicetestmodel, setrunservicetestmodel] = useState(false)

  const [showteststartednotify, setShowteststartednotify] = useState(false)

  const [swiftBoostData, setSwiftBoostData] = useState([])




  function setDslStability() {
    ref.current.continuousStart()
    let promise = axios.post("http://api.local.kowal.gg:8000/v1/service?serviceuuid=" + data.data.uuid, {
      apikey: cookies.get('token'),
      action: "setDslStability",
      profile: selecteddslprofile.id,
    })
    .then((response) => {
     ref.current.complete()
    });
    toast.promise(promise, {
      loading: 'Updating DSL Stability Profile',
      success: 'DSL Stability Profile updated',
      error: 'DSL Stability Profile request failed',
    }
    );
  }

  function activateBoost() {
    let promise = axios.post("http://api.local.kowal.gg:8000/v1/service?serviceuuid=" + data.data.uuid, {
      apikey: cookies.get('token'),
      action: "activateBoost",
    })
    .then((response) => {
      setSwiftBoostData(response.data)
    });
    toast.promise(promise, {
      loading: 'Activating Boost',
      success: 'Boost Activated',
      error: 'Boost failed to activate',
    }
    );
  }




 

  function runServiceTest(testType) {



if(testType == "HEALTH_CHECK"){
  let promise = axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + data.data.uuid, {
      apikey: cookies.get('token'),
      action: "runHealthCheck",
    })
    .then((response) => {
    });
    toast.promise(promise, {
      loading: 'Running Service Test',
      success: 'Service Test Successfully Requested. Please refresh your page',
      error: 'Service Test Request Failed',
    });
  }else{
 
    let promise = axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + data.data.uuid, {
      apikey: cookies.get('token'),
      action: "serviceTest",
      testType: testType
    })
    .then((response) => {
    });
    toast.promise(promise, {
      loading: 'Running Service Test',
      success: 'Service Test Successfully Requested. Please refresh your page',
      error: 'Service Test Request Failed',
    });
  }


  }




function getBoostData() {

  let promise = axios.post("http://api.local.kowal.gg:8000/v1/service?serviceuuid=" + data.data.uuid, {
    apikey: cookies.get('token'),
    action: "swiftboosts",
  })
  .then((response) => {
setSwiftBoostData(response.data)
  });
  toast.promise(promise, {
    loading: 'Getting SwiftBoost data',
    success: 'SwiftBoost data loaded',
    error: 'Error retrieving SwiftBoost data',
  }
  );
}

useEffect(() =>{ 
  getBoostData()
 }, [])
  return (
<div>
<Toaster position="bottom-right" />


<Transition.Root show={openservicetest} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenservicetest}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">NBN Service Test</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpenservicetest(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      {servicetestboxdata.type == "healthCheck" &&
                      <>
                        <div className="flex justify-around overflow-hidden rounded-lg bg-white shadow-lg p-6">
                        {servicetestboxdata?.overviewIndicator?.connectivity == 'Green' &&
                          <div className="bg-green-600 text-white p-3 rounded-md">
                            Connectivity
                          </div>
                        }
                        {servicetestboxdata?.overviewIndicator?.connectivity == 'Red' &&
                          <div className="bg-red-800 text-white p-3 rounded-md">
                            Connectivity
                          </div>
                        }
                        {servicetestboxdata?.overviewIndicator?.connectivity == 'Grey' &&
                          <div className="bg-slate-800 text-white p-3 rounded-md">
                            Connectivity
                          </div>
                        }
                        {servicetestboxdata?.overviewIndicator?.connectivity == 'Amber' &&
                          <div className="bg-yellow-800 text-white p-3 rounded-md">
                            Connectivity
                          </div>
                        }


                        {servicetestboxdata?.overviewIndicator?.performance == 'Green' &&
                          <div className="bg-green-600 text-white p-3 rounded-md">
                            Performance
                          </div>
                        }
                        {servicetestboxdata?.overviewIndicator?.performance == 'Red' &&
                          <div className="bg-red-800 text-white p-3 rounded-md">
                            Performance
                          </div>
                        }
                        {servicetestboxdata?.overviewIndicator?.performance == 'Amber' &&
                          <div className="bg-yellow-800 text-white p-3 rounded-md">
                            Performance
                          </div>
                        }
                        {servicetestboxdata?.overviewIndicator?.performance == 'Grey' &&
                          <div className="bg-slate-800 text-white p-3 rounded-md">
                            Performance
                          </div>
                        }


                        {servicetestboxdata?.overviewIndicator?.stability == 'Green' &&
                          <div className="bg-green-600 text-white p-3 rounded-md">
                            Stability
                          </div>
                        }
                        {servicetestboxdata?.overviewIndicator?.stability == 'Red' &&
                          <div className="bg-red-800 text-white p-3 rounded-md">
                            Stability
                          </div>
                        }
                        {servicetestboxdata?.overviewIndicator?.stability == 'Amber' &&
                          <div className="bg-yellow-800 text-white p-3 rounded-md">
                            Stability
                          </div>
                        }
                        {servicetestboxdata?.overviewIndicator?.stability == 'Grey' &&
                          <div className="bg-slate-800 text-white p-3 rounded-md">
                            Stability
                          </div>
                        }
 

                        </div>
                        {servicetestboxdata?.currentCondition?.status == 'Red' &&
                        <div className="border-l-4 border-red-400 bg-red-50 p-4 mt-16">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <p className="font-bold text-md text-red-700">
                                {servicetestboxdata?.currentCondition?.alertMessage} ({servicetestboxdata?.currentCondition?.code})
                                <br/>
                                <span className="font-medium text-red-700">
                                {servicetestboxdata?.currentCondition?.summary}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        }
                        {servicetestboxdata?.currentCondition?.status == 'Grey' &&
                        <div className="border-l-4 border-slate-400 bg-slate-50 p-4 mt-16">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationTriangleIcon className="h-5 w-5 text-slate-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <p className="font-bold text-md text-slate-700">
                                {servicetestboxdata?.currentCondition?.alertMessage} ({servicetestboxdata?.currentCondition?.code})
                                <br/>
                                <span className="font-medium text-slate-700">
                                {servicetestboxdata?.currentCondition?.summary}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        }
                        {servicetestboxdata?.currentCondition?.status == 'Amber' &&
                        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-16">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <p className="font-bold text-md text-yellow-700">
                                {servicetestboxdata?.currentCondition?.alertMessage} ({servicetestboxdata?.currentCondition?.code})
                                <br/>
                                <span className="font-medium text-yellow-700">
                                {servicetestboxdata?.currentCondition?.summary}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        }
                        {servicetestboxdata?.currentCondition?.status == 'Green' &&
                        <div className="border-l-4 border-green-400 bg-green-50 p-4 mt-16">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <p className="font-bold text-md text-green-700">
                                {servicetestboxdata?.currentCondition?.alertMessage} ({servicetestboxdata?.currentCondition?.code})
                                <br/>
                                <span className="font-medium text-green-700">
                                {servicetestboxdata?.currentCondition?.summary}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        }
                        

<div className="relative mt-16">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-sm text-gray-500">Advanced</span>
      </div>
</div>







      <div className="mt-16 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto">
          <div className="inline-block min-w-full align-middle md:px-2 lg:px-4 py-1">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="py-3 pl-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {servicetestboxdata?.healthCategory?.map((healthCategory) => (
                    <Fragment key={healthCategory.type}>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                          {healthCategory.type}
                        </th>
                      </tr>
                      {healthCategory?.healthCategoryItem?.map((healthCategoryItem, healthCategoryItemIdx) => (
                        <tr
                          key={healthCategoryItemIdx}
                          className={classNames(healthCategoryItemIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div className="font-medium text-gray-900">{healthCategoryItem["@type"]}</div>
                            <div className="text-gray-500 text-[0.800rem]">{healthCategoryItem.id}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{healthCategoryItem.value} {healthCategoryItem?.unit}</td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
                      </>
                      }
                      {servicetestboxdata.type == "serviceTest" &&
                      <>



                        {servicetestboxdata?.serviceTestResults[0]?.result == 'Passed' &&
                        <div className="border-l-4 border-green-400 bg-green-50 p-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <p className="font-bold text-md text-green-700">
                                Test Passed
                                <br/>
                                <span className="font-medium text-green-700">
                                Service test was passed successfully.
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        }
                        {servicetestboxdata?.serviceTestResults[0]?.result != 'Passed' &&
                        <div className="border-l-4 border-red-400 bg-red-50 p-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <p className="font-bold text-md text-red-700">
                                Test Failed
                                <br/>
                                <span className="font-medium text-red-700">
                                Service test was not passed successfully.
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        }

{servicetestboxdata?.serviceTestResults[0]?.testMeasure != undefined &&
<>
<div className="relative mt-16">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-sm text-gray-500">Advanced</span>
      </div>
</div>


      <div className="mt-16 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto">
          <div className="inline-block min-w-full align-middle md:px-2 lg:px-4 py-1">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="py-3 pl-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">

                  
                  {Object.keys(servicetestboxdata?.serviceTestResults[0]?.testMeasure[0])?.map((healthCategory) => (
                    <Fragment key={healthCategory.toString()}>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                          {healthCategory.toString()}
                        </th>
                      </tr>
                      {servicetestboxdata?.serviceTestResults[0]?.testMeasure[0]?.[healthCategory]?.measurements?.map((healthCategoryItem, healthCategoryItemIdx) => (
                        <tr
                          key={healthCategoryItemIdx}
                          className={classNames(healthCategoryItemIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div className="font-medium text-gray-900">{healthCategoryItem?.name} {healthCategoryItem["@type"]}</div>
                            <div className="text-gray-500 text-[0.800rem]">{healthCategoryItem?.id}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{healthCategoryItem?.value} {healthCategoryItem?.unit}</td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </>
    }
                      </>
                      }
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
</Transition.Root>









<LoadingBar color='#e11d48' ref={ref} />
<div className="lg:flex lg:items-center lg:justify-between mb-20">
<div className="min-w-0 flex-1">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  NBN
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  {data.data.uuid}
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {data.data.addressDetails.formattedAddress}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {data.data.serviceDetails.locationId}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {data.data.plan.nickname} - {data.data.plan.amount / 100}/{data.data.plan.interval}
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">


        <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          Actions
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a href={"/cancel/" + data.data.uuid}>
                <span
                  className={classNames(
                    active ? 'bg-red-600 text-white' : 'text-gray-700 bg-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Cancel
                </span>
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>


        {/* Dropdown */}

      </div>
    </div>



    <div class="mb-20">
    <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={currenttab}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <a
              key={tab.name}
              onClick={() => setTab(tab.tabnum)}
              className={classNames(
                tab.tabnum == currenttab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
              )}
              aria-current={tab.tabnum == currenttab ? 'page' : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.tabnum == currenttab ? 'bg-indigo-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </a>
          ))}
        </nav>
      </div>
    </div>


  {currenttab == 0 &&
    <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Service Details</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Service information</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Address</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.addressDetails.formattedAddress}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">NBN Location ID</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.serviceDetails.locationId}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Connection Type</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.serviceDetails.accessTechnology.subType}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">AVC</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.serviceDetails.avcId}</dd>
          </div>

        </dl>
      </div>
    </div>
}
  {currenttab == 1 &&
  <>
  {(data.data.serviceDetails.accessTechnology.baseType == "NCAS") &&
    <Chart options={chart.options} series={chart.series} type="area" width="100%" height={320} className="border p-3 mb-8" />
  }

    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data?.data?.healthChecks?.map((healthcheck, healthcheckidx) => (
          <li key={healthcheckidx}>
            <a onClick={() => {setservicetestboxdata(healthcheck); setOpenservicetest(true)}} className="block hover:bg-gray-50 cursor-pointer">
              {healthcheck.type == "healthCheck" && 
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="truncate text-sm font-medium text-indigo-600">Health Check</p>
                  <div className="ml-2 flex flex-shrink-0">
                    <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      Complete
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      <LinkIcon className={`mr-1.5 h-5 w-5 flex-shrink-0 text-${healthcheck.overviewIndicator.connectivity.toLowerCase()}-400`} aria-hidden="true" />
                      Connectivity
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      <InboxArrowDownIcon className={`mr-1.5 h-5 w-5 flex-shrink-0 text-${healthcheck.overviewIndicator.performance.toLowerCase()}-400`} aria-hidden="true" />
                      Performance
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      <ShareIcon className={`mr-1.5 h-5 w-5 flex-shrink-0 text-${healthcheck.overviewIndicator.stability.toLowerCase()}-400`} aria-hidden="true" />
                      Stability
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <p>
                      {healthcheck.created}
                    </p>
                  </div>
                </div>
              </div>
              }
              {healthcheck.type == "serviceTest" && 
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="truncate text-sm font-medium text-indigo-600">{healthcheck?.serviceTestResults[0]?.type}</p>
                  <div className="ml-2 flex flex-shrink-0">
                    <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      Complete
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      <LinkIcon className={`mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400`} aria-hidden="true" />
                      {healthcheck?.serviceTestResults[0]?.result}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <p>
                      {healthcheck.created}
                    </p>
                  </div>
                </div>
              </div>
              }
            </a>
          </li>
        ))}
      </ul>
    </div>
  </>
}
  {currenttab == 2 &&
  <>
    <div className="lg:border-t lg:border-b lg:border-gray-200">
      <nav className="" aria-label="Progress">
        <ol
          role="list"
          className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
        >
          {swiftBoostData?.boosts?.map((step, stepIdx) => (
            <li key={step.id} className="relative overflow-hidden lg:flex-1">
              <div
                className={classNames(
                  stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
                  stepIdx === steps.length - 1 ? 'border-t-0 rounded-b-md' : '',
                  'border border-gray-200 overflow-hidden lg:border-0'
                )}
              >
                {step.status == 'used' ? (
                  <a href={step.href} className="group">
                    <span
                      className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'px-6 py-5 flex items-start text-sm font-medium'
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600">
                          <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 flex min-w-0 flex-col">
                        <span className="text-sm font-medium">SwiftBoost</span>
                        <span className="text-sm font-medium text-gray-500">NBN 100/40</span>
                      </span>
                    </span>
                  </a>
                ) : step.status == 'active' ? (
                  <a href={step.href} aria-current="step">
                    <span
                      className="absolute top-0 left-0 h-full w-1 bg-indigo-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'px-6 py-5 flex items-start text-sm font-medium'
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600">
                          <span className="text-indigo-600">{step.id}</span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium">SwiftBoost</span>
                        <span className="text-sm font-medium text-gray-500">NBN 100/40</span>
                      </span>
                    </span>
                  </a>
                ) : (
                  <a href={step.href} className="group">
                    <span
                      className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'px-6 py-5 flex items-start text-sm font-medium'
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                          <span className="text-gray-500">{step.id}</span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium">SwiftBoost</span>
                        <span className="text-sm font-medium text-gray-500">NBN 100/40</span>
                      </span>
                    </span>
                  </a>
                )}

                {stepIdx !== 0 ? (
                  <>
                    {/* Separator */}
                    <div className="absolute inset-0 top-0 left-0 hidden w-3 lg:block" aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                      </svg>
                    </div>
                  </>
                ) : null}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
    {swiftBoostData.active == 0 && swiftBoostData.avaliable > 0 && 
    
    
      <div className="bg-white shadow sm:rounded-lg mt-20">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Activate Boost</h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            <p>
              Activate a boost to experience faster internet speeds for 24 hours.
            </p>
          </div>
          <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
            <button
              type="button"
              onClick={() => activateBoost()}
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            >
              Activate
            </button>
          </div>
        </div>
      </div>
    </div>
    
    }
    </>
}

{currenttab == 3 &&
    <div className="">
      <div className="grid grid-cols-3 gap-4">
      {(data?.data?.serviceDetails?.accessTechnology?.subType == "FTTN" || data?.data?.serviceDetails?.accessTechnology?.subType == "FTTB") &&
        <div className="divide-y divide-gray-200 overflow-show rounded-lg bg-white shadow col-span-1">
          <div className="px-4 py-5 sm:px-6">
            DSL Stability Profile
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className="">
              <Listbox value={selecteddslprofile} onChange={setselecteddslprofile}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium text-gray-700">DSL Profile</Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                        <span className="block truncate">{selecteddslprofile.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {dslProfiles.map((profile) => (
                            <Listbox.Option
                              key={profile.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={profile}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                    {profile.name}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              <button
                type="button"
                onClick={() => setDslStability()}
                className="float-right mt-4 mb-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Set Profile
              </button>
            </div>
          </div>
        </div>
      }
        <div className="divide-y divide-gray-200 overflow-show rounded-lg bg-white shadow col-span-2">
          <div className="px-4 py-5 sm:px-6">
            Service Test
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className="">
              <Listbox value={selectedServiceTest} onChange={setselectedServiceTest}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium text-gray-700">Test Type</Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                        <span className="block truncate">{selectedServiceTest.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {serviceTests.map((test) => (
                            <>
                          {((test.serviceType == data.data.serviceDetails.accessTechnology.subType) || (test.serviceType == "all")) &&
                            <Listbox.Option
                              key={test.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={test}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                    {test.name}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                            }
                            </>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              <button
                type="button"
                onClick={() => {runServiceTest(selectedServiceTest.code)}}
                className="float-right mt-4 mb-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Start Test
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
}
    </div>
  )
}
