/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, MapPinIcon, UsersIcon, CurrencyDollarIcon, ComputerDesktopIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef, Fragment } from 'react'
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';
import { Dialog, Transition, Combobox  } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const axios = require('axios').default;
const cookies = new Cookies();




const navigation = [
  { name: 'Docs', comp: 'docs', current: false },
  { name: 'Create Ticket', comp: 'createTicket', current: false },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}







function Docs() {

  const [wysiwygvalue, setwysiwygvalue] = useState('');
  const [docstitle, setdocstitle] = useState('');

  

  function createDoc(){

   axios.post("https://api.cloud.swiftfiber.com.au/v1/admin", {
     apikey: cookies.get('token'),
     action: "createdoc",
     data: "docs",
     title: docstitle,
     body: wysiwygvalue,
   })
   .then((response) => {
    setdocstitle('')
    setwysiwygvalue('')
   });
  }




  var modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
    
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
    
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
    
      ['clean']                                         // remove formatting button
    ],
  };

  var formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];
  return (
    <>
  <ReactQuill theme="snow" modules={modules}formats={formats} value={wysiwygvalue} onChange={setwysiwygvalue} />
  <div>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-5">
        Title
      </label>
      <div className="mt-1">
        <input
          type="text"
          name="title"
          id="title"
          value={docstitle}
          onChange={(e) => setdocstitle(e.target.value)}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>
    </div>
  <div className="mt-20 p-5 border-solid border-2 border-sky-500" dangerouslySetInnerHTML={{__html: wysiwygvalue}} ></div>

  <button
        type="button"
        onClick={() => createDoc()}
        className="mt-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Create
  </button>
    </>
  );
}
function CreateTicket() {

  const [ticketSubject, setticketSubject] = useState('');
  const [ticketMessage, setticketMessage] = useState('');

  

  function createTicket(){

    axios.post("https://api.cloud.swiftfiber.com.au/v1/admin", {
      apikey: cookies.get('token'),
      data: "createTicket",
      sub: selectedPerson.sub,
      subject: ticketSubject,
      message: ticketMessage,
    })
    .then((response) => {
     setticketMessage('')
     setticketSubject('')
    });
  }
  const [people, setPeople ] = useState([])
  useEffect(() =>{ 
    axios.post("https://api.cloud.swiftfiber.com.au/v1/admin", {
      apikey: cookies.get('token'),
      data: "allUsers",
    })
    .then((response) => {
      setPeople(response.data)
    });
   }, [])
  const [query, setQuery] = useState('')
  const [selectedPerson, setSelectedPerson] = useState(null)

  const filteredPeople =
    query === ''
      ? people
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase())
        })

        function classNames(...classes) {
          return classes.filter(Boolean).join(' ')
        }
 
  return (
    <>

<div>
<Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">User</Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person) => person?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{person.name} - {person.email}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
</div>
<div className='mt-6'>
    <label for="subject" class="block text-sm font-medium leading-6 text-gray-900">Subject</label>
    <div class="mt-2">
      <input onChange={(e) => setticketSubject(e.target.value)} value={ticketSubject} type="text" name="subject" id="subject" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Unpaid Invoice" />
    </div>
</div>
<div className='mt-6'>
    <label for="message" class="block text-sm font-medium leading-6 text-gray-900">Message</label>
    <div class="mt-2">
      <textarea onChange={(e) => setticketMessage(e.target.value)} value={ticketMessage} rows="4" name="message" id="message" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
    </div>
  </div>

<div className="mt-20 p-5 border-solid border-2 border-sky-500">
<div>Hi {selectedPerson?.name},</div>
<br/>
<div className="whitespace-pre-wrap">{ticketMessage}</div>
<br/>
<br/>
<div>Regards,
<br/>SwiftFiber team.
</div>
</div>

  <button
        type="button"
        onClick={() => createTicket()}
        className="mt-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Create
  </button>
    </>
  );
}


export default function Dedicatedservers() {
  const ref = useRef(null)


  const [activecomponent, setactivecomponent] = useState('none');





 useEffect(() =>{ 
  updatedata()
 }, [])

 function updatedata(){
  ref.current.continuousStart()
 axios.post("https://api.cloud.swiftfiber.com.au/v1/me", {
   apikey: cookies.get('token'),
 })
 .then((response) => {
  if(response.data.staff != 1){
    window.location.href = '/';
  }else{
 
  ref.current.complete()
  }
 });
}




  return (
   <div>
     <LoadingBar color='#e11d48' ref={ref} />






 

     
         <div className="sm:flex sm:items-center mb-5">
       <div className="sm:flex-auto">
         <h1 className="text-xl font-semibold text-gray-900">Admin</h1>
         <p className="mt-2 text-sm text-gray-700">
           Admin related stuff
         </p>
       </div>
     </div>

<div className="flex justify-start">
     <nav className="space-y-1" aria-label="Sidebar">
      {navigation.map((item) => (
        <a
          key={item.name}
          onClick={() => setactivecomponent(item.comp)}
          className={classNames(
            item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'flex items-center px-3 py-2 text-sm font-medium rounded-md'
          )}
          aria-current={item.current ? 'page' : undefined}
        >
          <span className="truncate">{item.name}</span>
        </a>
      ))}
    </nav>
    <div className="ml-5 w-full">

                {activecomponent === 'docs' ? (
                    <Docs />
                ) : activecomponent == 'createTicket' ? (
                    <CreateTicket />
                ) : null}


    </div>
</div>

 
    </div>
  )
}
