/* This example requires Tailwind CSS v2.0+ */
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Cookies from 'universal-cookie';
import LoadingBar from 'react-top-loading-bar'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'

const axios = require('axios').default;


const cookies = new Cookies();

export default function CancelService() {
 const ref = useRef(null);
 const [Services, setServices] = useState([]);
 const [serviceCanceled, setserviceCanceled] = useState(0);
 var apikey = cookies.get('token')
 let { uuid } = useParams();
 useEffect(() =>{ 
  updatedata()
 }, [])

 function updatedata(){
  ref.current.continuousStart()
 axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + uuid, {
   apikey: cookies.get('token'),
 })
 .then((response) => {
  setServices(response.data)
  ref.current.complete()
 });
 }

 function actionCancel() {
  ref.current.continuousStart()
  axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + Services.uuid, {
    apikey: cookies.get('token'),
    action: "cancel",
  })
  .then((response) => {
   ref.current.complete()
   setserviceCanceled(1)

  });
}


  return (
<>
<LoadingBar color='#e11d48' ref={ref} />
{serviceCanceled == 1 &&
<div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mb-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            Service Canceled
          </p>
        </div>
      </div>
    </div>
  }

    


<div className={"bg-white shadow sm:rounded-lg " + (Services.length == 0 ? 'loading-comp' : '')}>
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Cancel <span className="uppercase">{Services?.type}</span></h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Once you cancel your <span className="uppercase">{Services?.type}</span> service, you will lose all data associated with it.</p>
        </div>
        <div className="mt-5">
          {(Services?.type == "virtualserver" && serviceCanceled == 0) &&
            <button
              type="button"
              onClick={actionCancel}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
            >
              Cancel Service
            </button>
          }
    {Services?.type && Services?.type != "virtualserver" &&
    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            You must submit a {' '}
            <a href="/support" className="font-medium text-yellow-700 underline hover:text-yellow-600">
              support ticket {' '}
            </a>
            to cancel this service.
          </p>
        </div>
      </div>
    </div>
    }
        </div>
      </div>
    </div>

   
    </>
  )
}
