import { Fragment, useState, useRef, useEffect } from 'react'
import { Listbox, Transition, RadioGroup, Dialog  } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, CheckCircleIcon, ClockIcon  } from '@heroicons/react/20/solid'
import LoadingBar from 'react-top-loading-bar'
/* This example requires Tailwind CSS v2.0+ */
import { MegaphoneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Cookies from 'universal-cookie';
const locationjson = [
  { id: 1, name: 'Melbourne, Australia', online: true },
  { id: 2, name: 'Sydney, Australia', online: false },
  { id: 3, name: 'Singapore, Singapore', online: false },
  { id: 4, name: 'Los Angeles, United States', online: false },
  { id: 5, name: 'Amsterdam, Netherlands', online: false },
  { id: 6, name: 'London, United Kingdom', online: false },
  { id: 7, name: 'Frankfurt Germany', online: false },
  { id: 8, name: 'Toronto, Canada', online: false },
  { id: 9, name: 'Paris, France', online: false },
  { id: 10, name: 'Tokyo, Japan', online: false },
  { id: 11, name: 'Warsaw, Poland', online: false },
  { id: 12, name: 'Madrid, Spain', online: false },
]
const axios = require('axios').default;




  

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const cookies = new Cookies();

export default function Example() {
  const ref = useRef(null)
  const [location, setLocation] = useState(locationjson[0])
  const appType = [
    { id: 1, title: 'Docker', code:"dockerfile", description: 'Use a custom Dockerfile in your repo', icon: 'https://swiftportal.s3.ap-southeast-2.amazonaws.com/operationIcons/docker.svg' },
    { id: 2, title: 'NodeJS', code:"nodejs", description: 'Deploy a NodeJS app', icon: 'https://swiftportal.s3.ap-southeast-2.amazonaws.com/operationIcons/nodedotjs.svg' },
    { id: 3, title: 'NextJS', code:"nextjs", description: 'Deploy a NextJS app', icon: 'https://swiftportal.s3.ap-southeast-2.amazonaws.com/operationIcons/nextdotjs.svg' },
    { id: 4, title: 'React', code:"react", description: 'Deploy a React app', icon: 'https://swiftportal.s3.ap-southeast-2.amazonaws.com/operationIcons/react.svg' },
  ]
  const [selectedappType, setappType] = useState(appType[0])
  const [repos, setRepos] = useState([])

  const [deployStatus, setdeployStatus] = useState({})
  const [selectedRepo, setSelectedRepo] = useState([{
    id:0,
    full_name:"loading...",
  }])
  var locationid = location.id;

  const [open, setOpen] = useState(false)

  const cancelButtonRef = useRef(null)
 
function deployOperation() {
  axios.post("http://localhost:8000/v1/operations", {
    apikey: cookies.get('token'),
    api: "deployOperation",
    apptype: selectedappType.code,
    location: location.id,
    repo_full_name: selectedRepo.full_name,

  })
  .then((responsed) => {
    setOpen(true)

    setInterval(() => {
      

      axios.post("http://localhost:8000/v1/operations", {
        apikey: cookies.get('token'),
        api: "deployStatus",
        deploymentuuid: responsed.data.deploymentuuid,
        imageuuid: responsed.data.imageuuid,
    
      })
      .then((response) => {
        setdeployStatus(response.data)
        setOpen(true)
        if(response.data.operationid != "" && response.data.image != 0 && response.data.operation != 0){
          setTimeout(() => {
            window.location.href = '/operation/' + response.data.operationid; 
          }, 2500);

        }
      });



    }, 1000);

});
}




    useEffect(() => {
        // ref.current.continuousStart()
        axios.post("http://localhost:8000/v1/me", {
          apikey: cookies.get('token'),
          action: "getRepos",
        })
    .then((response) => {
      setRepos(response.data)
      setSelectedRepo(response.data[0])
      //  ref.current.complete()
   
    });
    }, []);
  



  var apikey = cookies.get('token')
  return (
    <>
<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>

                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-2xl mb-6 font-semibold leading-6 text-gray-900">
                      Deploying
                    </Dialog.Title>
                    <div className="mt-2">
                      <ul role="list" className="space-y-3">
                          <li key="0" className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6 flex items-center justify-between">
                            <div><span className="text-lg font-normal">Building Image</span></div>
                            <div>
                              {(deployStatus.image == undefined || deployStatus.image == 0) && 
                              <div role="status">
                                  <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                  </svg>
                                  <span class="sr-only">Loading...</span>
                              </div>
                              }
                              {(deployStatus.image == 1) && 
                                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                              }
                            </div>
                          </li>
                          <li key="1" className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6 flex items-center justify-between">
                          <div><span className="text-lg font-normal">Building Operation</span></div>
                            <div>
                              {(deployStatus.operation == 0 && deployStatus.image == 1) && 
                              <div role="status">
                                  <svg aria-hidden="true" class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                  </svg>
                                  <span class="sr-only">Loading...</span>
                              </div>
                              }
                              {(deployStatus.operation == 1) && 
                                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                              }
                              {(deployStatus.operation == 0 && deployStatus.image == 0) && 
                                <ClockIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                              }
                            </div>
                          </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
      <div>
        <input value={location.id} name="location" id="location" hidden></input>
        <input value={apikey} name="apikey" id="apikey" hidden></input>
        <input value='operation' name="service" id="service" hidden></input>


      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Location</h3>
              <p className="mt-1 text-sm text-gray-600">
                Where your operation will be hosted.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <Listbox value={location} onChange={setLocation}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-gray-700">Location</Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                      <span
                        aria-label={location.online ? 'Online' : 'Offline'}
                        className={classNames(
                          location.online ? 'bg-green-400' : 'bg-gray-200',
                          'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                        )}
                      />
                      <span className="ml-3 block truncate">{location.name}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {locationjson.map((person) => (
                        <Listbox.Option
                          key={person.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={person}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    person.online ? 'bg-green-400' : 'bg-gray-200',
                                    'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                                  )}
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(location ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                >
                                  {person.name}
                                  <span className="sr-only"> is {person.online ? 'online' : 'offline'}</span>
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Repository</h3>
              <p className="mt-1 text-sm text-gray-600">
                Select a repository to deploy from your github.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <Listbox value={selectedRepo} onChange={setSelectedRepo}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Repository</Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selectedRepo?.full_name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {repos?.map((repo) => (
                  <Listbox.Option
                    key={repo?.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-8 pr-4'
                      )
                    }
                    value={repo}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {repo?.full_name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
          </div>
        </div>
      </div>


      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">App Type</h3>
              <p className="mt-1 text-sm text-gray-600">
                Select what type of app you want to deploy.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <RadioGroup value={selectedappType} onChange={setappType}>
      <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
        App
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {appType.map((apptype) => (
          <RadioGroup.Option
            key={apptype.id}
            value={apptype}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-indigo-600 ring-2 ring-indigo-600' : '',
                'relative cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {apptype.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {apptype.description}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="div" className="mt-6 text-sm font-medium text-gray-900 flex justify-center items-center">
                      <img className="h-24" src={apptype.icon} />
                    </RadioGroup.Description>
                  </span>
                </span>
                
                <CheckCircleIcon
                  className={classNames(apptype.id != selectedappType.id ? 'invisible' : '', 'h-5 w-5 text-indigo-600 absolute bottom-3 left-3')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    apptype.id == selectedappType.id ? 'border-indigo-600' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
                
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
          </div>
        </div>
      </div>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">App Type</h3>
              <p className="mt-1 text-sm text-gray-600">
                Select what type of app you want to deploy.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <textarea
              rows={3}
              name="comment"
              id="comment"
              className="block w-full resize-none border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:py-1.5 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."
              defaultValue={''}
            />
          </div>
        </div>
      </div>




      {/* <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Addons</h3>
              <p className="mt-1 text-sm text-gray-600">Add ons for your server.</p>
            </div>
            
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">




          <RadioGroup value={selectedManagement} onChange={setSelectedManagement}>
      <RadioGroup.Label className="text-base font-medium text-gray-900">Management</RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {management.map((mailingList) => (
          <RadioGroup.Option
            key={mailingList.id}
            value={mailingList}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {mailingList.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                    {mailingList.features.map((feature) => (
                      <>
                        {feature.text}
                        <br></br>
                      </>
                    ))}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                      
                      {mailingList.price == 0 ? 'Included': '$ ' + mailingList.price}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-500' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>



          </div>
        </div>
      </div> */}





      <div class="hidden sm:block mt-10 mb-10" aria-hidden="true"><div class="py-5"><div class="border-t border-gray-200"></div></div></div>


      <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Server Overview</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Details for your server in {location.name}.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Location</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{location.name}</dd>
          </div>
          <button
        type="button"
        onClick={deployOperation}
        className="mr-5 mb-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Deploy
      </button>
        </dl>
        
      </div>
    </div>



      </div>

    </>
  )
}
