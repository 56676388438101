import logo from './logo.svg';
import { BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import { Dialog, Transition, Disclosure } from '@headlessui/react'

import './App.css';
import Cookies from 'universal-cookie';
import Moment from 'react-moment';
import Home from "./pages/Home";
import Virtualservers from "./pages/Virtualservers";
import Domain from "./pages/Domain";
import Dedicatedservers from "./pages/Dedicatedservers";
import Support from "./pages/Support";
import Supportticket from "./pages/Supportticket";
import Supportdoc from "./pages/Supportdoc";
import Network from "./pages/Network";
import Nbn from "./pages/Nbn";
import NavBar from './components/NavBar';
import Deployvirtualserver from './pages/Deployvirtualserver';
import Admin from './pages/Admin';
import Deploydedicatedserver from './pages/Deploydedicatedserver';
import Deploydomain from './pages/Deploydomain';
import Me from './pages/Me';
import CancelService from './pages/cancelService';
import Teams from './pages/Teams';
import Billing from './pages/Billing';
import Deploynbn from './pages/Deploynbn';
import Deployoperation from './pages/Deployoperation';
import ViewOperation from './pages/Viewoperation';
import Showservice from './pages/Showservice';
import UnknownServices from './pages/UnknownServices';
import Operations from './pages/Operations';
import Initialauth from './pages/Initialauth';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import {

  Bars3Icon,
  BeakerIcon,
  HomeIcon,
  CpuChipIcon,
  GlobeAsiaAustraliaIcon,
  CubeTransparentIcon,
  LifebuoyIcon,
  CreditCardIcon,
  XMarkIcon,
  ChevronRightIcon,
  CloudIcon,
  UserIcon,
  DocumentDuplicateIcon,
  ChartPieIcon
 } from '@heroicons/react/24/outline'

import React, { useEffect, useState, Fragment, } from 'react'
import LoadingBar from 'react-top-loading-bar'



const axios = require('axios').default;


 
 const teams = [
  { name: 'Network Status', initial:"N", href: 'https://status.swiftfiber.com.au/' },
 ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const cookies = new Cookies();
function RedirectNotauthed() {

  if(cookies.get('token') == null){
    window.location.href = 'https://api.cloud.swiftfiber.com.au/auth'; 
    }

}
function Notauthed() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/auth/token/:token" element={<Initialauth />}>
      </Route>
      <Route path="/*" element={<RedirectNotauthed />}>
      </Route>
    </Routes>
  </BrowserRouter>
    )
}

function Authed() {
  const [incidents, setIncidents] = useState([])

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [user, setUser] = useState([])





  
  useEffect(() => {

    axios.post("https://api.cloud.swiftfiber.com.au/v1/me", {
      apikey: cookies.get('token'),
    })
    .then((response) => {

      var currentLocation = window.location;
      if(response?.data?.stripe?.address?.country == null && currentLocation.pathname != "/me"){
        window.location.href = '/me'; 
      }
      setUser(response.data)
    });
  
      // ref.current.continuousStart()
  axios.get("https://status.swiftfiber.com.au/api/v2/incidents/unresolved.json", {
  })
  .then((response) => {
    setIncidents(response.data.incidents)
    // ref.current.complete()
  
  });
  }, []);

  var navigation = [
    { name: 'Home', href: '/', icon: HomeIcon, current: false },
    { name: 'Services', icon: CubeTransparentIcon, current: false, children: [
      { name: 'Virtual Servers', icon: CubeTransparentIcon, href: '/services/virtualservers' },
      { name: 'Dedicated Servers', icon: CpuChipIcon, href: '/services/dedicatedservers' },
      { name: 'NBN', icon: GlobeAsiaAustraliaIcon, href: '/services/nbn' },
      { name: 'Other', icon: DocumentDuplicateIcon, href: '/services/other' },
    ], },
    { name: 'Network', href: '/network', icon: CubeTransparentIcon, current: false, beta:1 },
    { name: 'Support', href: '/support', icon: LifebuoyIcon, current: false },
    { name: 'Billing', href: 'https://api.cloud.swiftfiber.com.au/billing', icon: CreditCardIcon, current: false },
   
   ]
if(user.staff == 1){
  navigation.push({ name: 'Admin', href: '/admin', icon: UserIcon, current: false })
  navigation[1].children.push({ name: 'Operations', icon: CloudIcon, href: '/operations', beta:1 })
}
  return (
    <>
{incidents?.map((status) => (
  <>
  {status.impact != "none" &&
    <div className="flex items-center justify-between gap-x-6 bg-gray-900 py-2.5 px-6 sm:pr-3.5 lg:pl-8">
    <p className="text-sm leading-6 text-white">
      <a href={status.shortlink} target="_blank">
      <strong className="font-semibold">
        {' '}{status.status.replace("_"," ")}
        {/* {status?.components?.map((component) => (
          <>
           {' '} - {component.description}
          </>
        ))} */}
        </strong>
      <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
        <circle cx={1} cy={1} r={1} />
      </svg>
      {status.incident_updates[0].body}
      {/* &nbsp;<span aria-hidden="true">&rarr;</span> */}
    </a>
    </p>
    <span className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px] text-white text-sm">
    <Moment format="Do MMM YYYY [@] LT">{status.incident_updates[0].updated_at}</Moment>
    </span>
  </div>
}
</>
    ))}

    <div className="min-h-full">
    {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}

    {/* <NavBar /> */}
    <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://swiftportal.s3.ap-southeast-2.amazonaws.com/logo_light.svg"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                <li key={item.name}>
                  {!item.children ? (
                    <a
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-800' : 'hover:bg-gray-800',
                        'block rounded-md py-2 pr-2 pl-10 text-sm leading-6 font-semibold text-white'
                      )}
                    >
                      {item.name}
                    </a>
                  ) : (
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              item.current ? 'bg-gray-800' : 'hover:bg-gray-800',
                              'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-white'
                            )}
                          >
                            <ChevronRightIcon
                              className={classNames(
                                open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                'h-5 w-5 shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Disclosure.Button>
                          <Disclosure.Panel as="ul" className="mt-1 px-2">
                            {item.children.map((subItem) => (
                              <li key={subItem.name}>
                                <Disclosure.Button
                                  as="a"
                                  href={subItem.href}
                                  className={classNames(
                                    subItem.current ? 'bg-gray-800' : 'hover:bg-gray-800',
                                    'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-white'
                                  )}
                                >
                                  {subItem.name}
                                </Disclosure.Button>
                              </li>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )}
                </li>
              ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">Other</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-slate-900 px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="https://swiftportal.s3.ap-southeast-2.amazonaws.com/logo_light.svg"
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                <li key={item.name}>
                  {!item.children ? (
                    <a
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-800' : 'hover:bg-gray-800 hover:text-white',
                        'rounded-md py-2 pr-2 text-sm leading-6 font-semibold text-slate-300 group flex gap-x-3 p-2'
                      )}
                    >
                      <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                      {item.name}
                                  {item?.beta && 
                                    <span className="inline-flex items-center rounded-full bg-gray-700 px-2 py-0.5 text-xs font-medium text-slate-300">
                                    Beta
                                    </span>
                                  }
                    </a>
                  ) : (
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              item.current ? 'bg-gray-800' : 'hover:bg-gray-800 hover:text-white',
                              'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-slate-300'
                            )}
                          >
                            <ChevronRightIcon
                              className={classNames(
                                open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                'h-5 w-5 shrink-0'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Disclosure.Button>
                          <Disclosure.Panel as="ul" className="mt-1 px-2 border-l-2 border-slate-600">
                            {item.children.map((subItem) => (
                              <li key={subItem.name}>
                                <Disclosure.Button
                                  as="a"
                                  href={subItem.href}
                                  className={classNames(
                                    subItem.current ? 'bg-gray-800' : 'hover:bg-gray-800 hover:text-white',
                                    'flex gap-x-3 rounded-md py-2 pr-2 pl-5 text-sm leading-6 text-slate-300'
                                  )}
                                >
                                  <subItem.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {subItem.name}
                                  {subItem?.beta && 
                                    <span className="inline-flex items-center rounded-full bg-gray-700 px-2 py-0.5 text-xs font-medium text-slate-300">
                                    Beta
                                    </span>
                                  }
                                </Disclosure.Button>
                              </li>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )}
                </li>
              ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Other</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <a
                    href="/me"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
                  >
                    <img
                      className="h-8 w-8 rounded-full bg-gray-800"
                      src={user.imageUrl}
                      alt=""
                    />
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">{user?.stripe?.name}</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">SwiftFiber</div>
          <a href="/me">
            <span className="sr-only">Your profile</span>
            <img
              className="h-8 w-8 rounded-full bg-gray-800"
              src={user.imageUrl}
              alt=""
            />
          </a>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-28"><Router/></div>
        </main>
      </div>
</div>
</>
  );
}





function Router() {

  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />}>
      </Route>
      <Route path="/me" element={<Me />}>
      </Route>
      <Route path="/cancel/:uuid" element={<CancelService />}>
      </Route>
      <Route path="/billing" element={<Billing />}>
      </Route>
      <Route path="/teams" element={<Teams />}>
      </Route>
      <Route path="/admin" element={<Admin />}>
      </Route>
      <Route path="/support" element={<Support />}>
      </Route>
      <Route path="/network" element={<Network />}>
      </Route>
      <Route path="/support/ticket/:uuid" element={<Supportticket />}>
      </Route>
      <Route path="/support/doc/:uuid" element={<Supportdoc />}>
      </Route>
      <Route path="/services/virtualservers" element={<Virtualservers />}>
      </Route>
      <Route path="/services/dedicatedservers" element={<Dedicatedservers />}>
      </Route>
      <Route path="/services/other" element={<UnknownServices />}>
      </Route>
      <Route path="/services/domains" element={<Domain />}>
      </Route>
      <Route path="/services/nbn" element={<Nbn />}>
      </Route>
      <Route path="/operations" element={<Operations />}>
      </Route>
      <Route path="/deploy/virtualserver" element={<Deployvirtualserver />}>
      </Route>
      <Route path="/deploy/dedicatedserver" element={<Deploydedicatedserver />}>
      </Route>
      <Route path="/deploy/domain" element={<Deploydomain />}>
      </Route>
      <Route path="/deploy/nbn" element={<Deploynbn />}>
      </Route>
      <Route path="/deploy/operation" element={<Deployoperation />}>
      </Route>
      <Route path="/sub/:uuid" element={<Showservice />}>
      </Route>
      <Route path="/operation/:uuid" element={<ViewOperation />}>
      </Route>
      <Route path="/auth/token/:token" element={<Initialauth />}>
      </Route>
    </Routes>
  </BrowserRouter>
  )
  
}





  const DefaultRouteHandler = () => (
    !cookies.get('token') ? <Notauthed /> : <Authed />
);

export default DefaultRouteHandler;
