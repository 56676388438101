/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect, useRef } from 'react'
import Cookies from 'universal-cookie';
import LoadingBar from 'react-top-loading-bar'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
var moment = require('moment');
const axios = require('axios').default;


const cookies = new Cookies();

export default function Me() {
 const ref = useRef(null);
 var apikey = cookies.get('token')
 const [invoices, setInvoices] = useState([]);
 const [paymentMethods, setPaymentMethods] = useState([]);
 const [openinvoicemodel, setopeninvoicemodel] = useState(false);
 const [invoicemodelnum, setinvoicemodelnum] = useState(0);

 useEffect(() =>{ 
  updateuser()
 }, [])

 function updateuser(){
 ref.current.continuousStart()
 axios.post("http://localhost:8000/v1/me", {
   apikey: cookies.get('token'),
   action:"invoices"
 })
 .then((response) => {
   setInvoices(response?.data)
   ref.current.complete()
 });
 axios.post("http://localhost:8000/v1/me", {
   apikey: cookies.get('token'),
   action:"paymentMethods"
 })
 .then((response) => {
   setPaymentMethods(response?.data)
   ref.current.complete()
 });
}



  return (
    
<div>
<LoadingBar color='#e11d48' ref={ref} />

      <div className="grid grid-cols-2 gap-4">
        <div>


        </div>
        <div>
          <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Invoices</h1>
            <p className="mt-2 text-sm text-gray-700">
              Your Invoices
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Total
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Created
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Download</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {invoices?.map((invoice, invoiceIdx) => (
                      <tr key={invoice.number} className={invoiceIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {invoice.number}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${invoice.total} <span className="uppercase">{invoice.currency}</span></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(invoice.created * 1000).format("MM MMM YYYY")}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{invoice.status}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a href={invoices[invoicemodelnum]?.invoice_pdf} className="text-indigo-600 hover:text-indigo-900">
                            Download<span className="sr-only">, {invoice.number}</span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>

      </div>
   
    </div>

  )
}