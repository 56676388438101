/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef, Fragment } from 'react'
import { useParams } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';
import {
  BriefcaseIcon,
  PaperClipIcon,
  ClockIcon,
  ArrowDownCircleIcon,
  ChevronRightIcon,
  BuildingOfficeIcon,
  CreditCardIcon,
  UserIcon,
  UsersIcon

} from '@heroicons/react/20/solid'
import { Menu, Transition, Switch } from '@headlessui/react'
const axios = require('axios').default;
const cookies = new Cookies();

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const tabs = [
  {name: 'Resources', tab: 0, icon: UserIcon, current: true },
  // { name: 'Attacks', tab: 1, icon: UserIcon, current: false },
]


export default function Network() {
  const ref = useRef(null)

 const [Data, setData] = useState([]);

 const [activecomponent, setactivecomponent] = useState(0);


function Iptab(){
return(




  <div className="-mx-4 mt-8 sm:-mx-0 ring-1 ring-gray-200">
  <table className="min-w-full divide-y divide-gray-200">
    <thead>
      <tr>
        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-slate-500 sm:pl-4">
          IP
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-medium text-slate-500 lg:table-cell"
        >
          Service
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-medium text-slate-500 sm:table-cell"
        >
          DNS
        </th>
        <th scope="col" className="px-3 py-3.5 text-left text-sm font-medium text-slate-500">
          Location
        </th>
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-200 bg-white">
      {Data?.ips?.map((ip) => (
        <tr key={ip?.ip}>
          <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-4">
          <span>{ip?.ip}</span><br/><span className="text-gray-500 font-normal">IP Address</span>
            <dl className="font-normal lg:hidden">
              <dt className="sr-only">IP</dt>
              <dd className="mt-1 truncate text-gray-700">{ip?.ip}</dd>
              <dt className="sr-only sm:hidden">Location</dt>
              <dd className="mt-1 truncate text-gray-500 sm:hidden">Unknown</dd>
            </dl>
          </td>
          <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell"><a href={`/sub/${ip?.serviceuuid}`} target="_blank" className='text-blue-500'>{ip?.serviceuuid}</a><br/><span className="text-gray-500 font-normal">{ip?.serviceType}</span></td>
          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{ip?.dns}</td>
          <td className="px-3 py-4 text-sm text-gray-500">{ip?.location}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>


);
}
function Attackstab(){
return(



    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      IP Address
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Volume
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Peak
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Duration
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Time
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {Data?.attacks?.map((attack) => (
                    <tr key={attack.attack_id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                      {attack.ip}
                      <br></br>
                      <span className="text-sm text-gray-500">{attack.description}</span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{attack.volume} MB</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{attack.max_peak} Mbps</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{attack.duration}s</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{attack.start_time}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {attack.status == "start" &&
                      <p className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                        Starting
                      </p>
                    }
                    {attack.status == "ongoing" &&
                      <p className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                        Ongoing
                      </p>
                    }
                    {attack.status == "end" &&
                      <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                        Completed
                      </p>
                    }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>



);
}




 useEffect(() =>{ 
  updatedata()
 }, [])

 function updatedata(){
  ref.current.continuousStart()
  axios.post("https://api.cloud.swiftfiber.com.au/v1/me", {
  // axios.post("http://api.local.kowal.gg:8000/v1/firewall", {
   apikey: cookies.get('token'),
   action: "MyNetwork",
 })
 .then((response) => {
  setData(response.data)
  ref.current.complete()
 });
}


// function actionReply() {
//   ref.current.continuousStart()
//   axios.post("https://api.cloud.swiftfiber.com.au/v1/support", {
//     apikey: cookies.get('token'),
//     uuid: uuid,
//     action: "reply",
//     view: "ticket",
//     message: Replybox,
//     status: Replystatus,
//   })
//   .then((response) => {
//   setServices(response.data)
//   setReplybox('')
//    ref.current.complete()
//   });
// }

  return (
   <div>
     <LoadingBar color='#e11d48' ref={ref} />
     <div className="lg:flex lg:items-center lg:justify-between mb-20">
      <div className="min-w-0 flex-1">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="/network" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Network
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  My Network
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        Network
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
          
          </div>
          {/* <div className="mt-2 flex items-center text-sm text-gray-500">
          Last Reply: 
          </div> */}
        </div>
      </div>

    </div>






    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                onClick={() => setactivecomponent(tab.tab)}
                className={classNames(
                  tab.tab == activecomponent ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.tab == activecomponent ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>






<>
{activecomponent == 0 &&
<Iptab />
}
{activecomponent == 1 &&
<Attackstab />
}
</>





    </div>
  )
}
