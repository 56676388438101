/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, MapPinIcon, UsersIcon, CurrencyDollarIcon, ComputerDesktopIcon, GlobeAltIcon, CpuChipIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef } from 'react'
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';



const axios = require('axios').default;
const cookies = new Cookies();


export default function UnknownServices() {
  const ref = useRef(null)

 const [Services, setServices] = useState([]);

 useEffect(() =>{ 
  updatedata()
 }, [])

 function updatedata(){
  ref.current.continuousStart()
 axios.post("https://api.cloud.swiftfiber.com.au/v1/services", {
   apikey: cookies.get('token'),
   filter: "unknown",
 })
 .then((response) => {
  setServices(response.data)
  ref.current.complete()
 });
}


  return (
   <div>
     <LoadingBar color='#e11d48' ref={ref} />
         <div className="sm:flex sm:items-center mb-5">
       <div className="sm:flex-auto">
         <h1 className="text-xl font-semibold text-gray-900">Other Services</h1>
         <p className="mt-2 text-sm text-gray-700">
           Other Services
         </p>
       </div>
     </div>


{Services?.pending > 0 &&

     <div class="rounded-md bg-blue-50 p-4 mb-4">
  <div class="flex">
    <div class="flex-shrink-0">

      <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
      </svg>
    </div>
    <div class="ml-3 flex-1 md:flex md:justify-between">
      <p class="text-sm text-blue-700">{ Services?.pending } service(s) pending creation.</p>
      {/* <p class="mt-3 text-sm md:mt-0 md:ml-6">
        <a href="#" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
          Details
          <span aria-hidden="true"> &rarr;</span>
        </a>
      </p> */}
    </div>
  </div>
</div>
}
<div>
      <div className="-mx-4 mt-8 sm:-mx-0 ring-1 ring-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-slate-500 sm:pl-4">
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-medium text-slate-500 lg:table-cell"
              >
                ID
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-medium text-slate-500 sm:table-cell"
              >
                Price
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-medium text-slate-500">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {Services?.services?.map((service) => (
              <tr key={service.uuid}>
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-4">
                <a><span>{service?.plan?.nickname}</span></a>
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only">Name</dt>
                    <dd className="mt-1 truncate text-gray-700">{service?.plan?.nickname}</dd>
                    <dt className="sr-only sm:hidden">Price</dt>
                    <dd className="mt-1 truncate text-gray-500 sm:hidden">${service?.plan?.amount / 100}/m</dd>
                  </dl>
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{service?.uuid}</td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">${service?.plan?.amount / 100}/m</td>
                <td className="px-3 py-4 text-sm text-gray-500">
                    <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                      Active
                    </span>

                  </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  )
}
