/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, MapPinIcon, UsersIcon, LinkIcon, CurrencyDollarIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid'
import { useState, useEffect } from 'react'

import Cookies from 'universal-cookie';
import Tickets from '../components/Tickets';
const axios = require('axios').default;
const cookies = new Cookies();

var user = cookies.get('user')
var time = new Date()

function formatAMPM(time) {
 var hours = time.getHours();
 var minutes = time.getMinutes();
 var ampm = hours >= 12 ? 'PM' : 'AM';
 hours = hours % 12;
 hours = hours ? hours : 12; // the hour '0' should be '12'
 minutes = minutes < 10 ? '0'+minutes : minutes;
 var strTime = hours + ':' + minutes + ' ' + ampm;
 return strTime;
}











export default function Home() {
  const [tickets, settickets] = useState()





  useEffect(() =>{ 
    updatedata()
   }, [])
  
   function updatedata(){
    axios.post("https://api.cloud.swiftfiber.com.au/v1/support", {
      apikey: cookies.get('token'),
      view: "tickets",
    })
    .then((response) => {
     settickets(response.data)
  
    });
  
  }
  






  return (
<>

         <div className="sm:flex sm:items-center mb-10">
       <div className="sm:flex-auto">
         <h1 className="text-xl font-semibold text-gray-900">My Dashboard</h1>
         <p className="mt-2 text-sm text-gray-700">
           Welcome back {user?.stripe?.name?.split(" ")[0]} 👋
         </p>
       </div>
       {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
         <a
           href="/deploy/domain"
           className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
         >
           Add Domain
         </a>
       </div> */}
     </div>







<div className="grid grid-cols-4 gap-8">
<div>
{/* <div className="md:flex md:items-center md:justify-between bg-gray-800 p-8 rounded-xl mb-20">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
          Hey {user?.stripe?.name?.split(" ")[0]} 👋
        </h2>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">
      <h2 className="text-2xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight">
          {formatAMPM(time)}
        </h2>
      </div>
</div> */}

<div className="border p-8 rounded">
  <div><img className="rounded-full w-16" src={user?.imageUrl} /></div>
  <div className="mt-2"><span className="font-semibold text-xl">{user?.stripe?.name}</span></div>
  <div className="mt-2">
    <span className="font-normal text-md text-gray-500">{user?.email}</span>
    <br/>
    <span className="font-normal text-md text-gray-500">+{user?.stripe?.phone}</span>
    <br/>
    <span className="font-normal text-md text-gray-500">{user?.stripe?.address?.line1}</span>
  </div>
  <div className="mt-4 flex justify-start gap-4">
      <a href="/me"><button
        type="button"
        className=" bg-green-50 px-2.5 py-1.5 text-sm font-semibold text-green-600 hover:bg-green-100"
      >
        Update
      </button></a>
  </div>
</div>



</div>
<div className="col-span-3">
<a href="/deploy/dedicatedserver"><img className="rounded" src="https://i.imgur.com/C45YbX7.png"/></a>




  </div>
</div>







</>
  )
}
