/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, UsersIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef, Fragment } from 'react'
import { useParams } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';
import {
  BriefcaseIcon,
  PaperClipIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
  ChatBubbleLeftEllipsisIcon,
  TagIcon,
  UserCircleIcon
} from '@heroicons/react/20/solid'
import { Menu, Transition, Switch } from '@headlessui/react'
const axios = require('axios').default;
const cookies = new Cookies();

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function Ticket() {
  let { uuid } = useParams();
  const ref = useRef(null)

 const [Services, setServices] = useState([]);









 useEffect(() =>{ 
  updatedata()
 }, [])

 function updatedata(){
  ref.current.continuousStart()
 axios.post("https://api.cloud.swiftfiber.com.au/v1/support", {
   apikey: cookies.get('token'),
   view: "doc",
   uuid: uuid,
 })
 .then((response) => {
  setServices(response.data)
  ref.current.complete()
 });
}




  return (
   <div>
     <LoadingBar color='#e11d48' ref={ref} />
     <div className="lg:flex lg:items-center lg:justify-between mb-20">
      <div className="min-w-0 flex-1">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="/support" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Doc
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  {Services.uuid}
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {Services.title}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
          Created: {Services?.date}
          </div>
        </div>
      </div>

    </div>




    <div dangerouslySetInnerHTML={{__html: Services?.body}} ></div>

    <div className="mt-2 flex items-center text-lg font-medium border-l-4 border-indigo-500 p-2 shadow-2xl rounded-md">
    <img  className="mr-1.5 h-7 w-7 flex-shrink-0 text-slate rounded-full" src={Services?.author_imageurl} />
    {Services?.author_name}
    </div>


    </div>
  )
}
