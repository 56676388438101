import { Fragment, useState, useRef, useEffect } from 'react'
import { Listbox, Transition, RadioGroup  } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, CheckCircleIcon  } from '@heroicons/react/20/solid'
import LoadingBar from 'react-top-loading-bar'
/* This example requires Tailwind CSS v2.0+ */
import { MegaphoneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Cookies from 'universal-cookie';
const locationjson = [
  { id: 1, name: 'Melbourne', online: true },
  { id: 2, name: 'Sydney', online: true },
]
const axios = require('axios').default;

const management = [
  { id: 1, title: 'Basic', features:[ {text:"Corero DDoS Protection"} ], price: 0 },
]



  

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const cookies = new Cookies();

export default function Example() {
  const ref = useRef(null)
  const [location, setLocation] = useState(locationjson[0])
  var locationid = location.id;
  const [plan, setPlan] = useState([])
  const [plans, setPlans] = useState([])
  const [selectedManagement, setSelectedManagement] = useState(management[0])
 





    useEffect(() => {
        // ref.current.continuousStart()
    axios.get("https://api.cloud.swiftfiber.com.au/v1/deploy?service=dedicatedserver", {
    })
    .then((response) => {
      setPlans(response.data)
      // ref.current.complete()
   
    });
    }, []);
  



  var apikey = cookies.get('token')
  return (
    <>
    <form action="https://api.cloud.swiftfiber.com.au/v1/deploy" method="post">
      <div>
        <input value={location.id} name="location" id="location" hidden></input>
        <input value={plan.id} name="plan" id="plan" hidden></input>
        <input value={management.id} name="management" id="management" hidden></input>
        <input value={apikey} name="apikey" id="apikey" hidden></input>
        <input value='dedicatedserver' name="service" id="service" hidden></input>


<div>




        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Location</h3>
              <p className="mt-1 text-sm text-gray-600">
                Where your server will be hosted.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <Listbox value={location} onChange={setLocation}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-gray-700">Location</Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                      <span
                        aria-label={location.online ? 'Online' : 'Offline'}
                        className={classNames(
                          location.online ? 'bg-green-400' : 'bg-gray-200',
                          'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                        )}
                      />
                      <span className="ml-3 block truncate">{location.name}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {locationjson.map((person) => (
                        <Listbox.Option
                          key={person.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={person}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    person.online ? 'bg-green-400' : 'bg-gray-200',
                                    'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                                  )}
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(location ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                >
                                  {person.name}
                                  <span className="sr-only"> is {person.online ? 'online' : 'offline'}</span>
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Plan</h3>
              <p className="mt-1 text-sm text-gray-600">How much compute your server will have.</p>
            </div>
            
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <RadioGroup value={plan} onChange={setPlan}>
      <RadioGroup.Label className="sr-only"> Server </RadioGroup.Label>
      <div className="space-y-4">
        {plans[locationid]?.map((plan) => (
          <RadioGroup.Option
            key={plan.id}
            value={plan}
            disabled={plan.instock == 0 ? true : false}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <RadioGroup.Label as="span" className="font-medium text-gray-900">
                      <div className="flex items-center">
                      <span>{plan.name}</span> <span className="text-gray-500 ml-2"></span>
                        {plan.instock == 0 &&
                          <h2>
                            <span class="ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">No stock</span>
                          </h2>
                        }
                        {plan.instock == 1 &&
                          <h2>
                            <span class="ml-2 inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">Pre-order</span>
                          </h2>
                        }
                        {plan.instock == 2 &&
                          <h2>
                            <span class="ml-2 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">In stock</span>
                          </h2>
                        }
                        {plan?.tags?.map((tag) => ( 
                          <>
                            {tag.color == 'green' &&
                              <h2>
                                <span class="ml-2 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">{tag.text}</span>
                              </h2>
                            }
                            {tag.color == 'yellow' &&
                              <h2>
                                <span class="ml-2 inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">{tag.text}</span>
                              </h2>
                            }
                            {tag.color == 'red' &&
                              <h2>
                                <span class="ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">{tag.text}</span>
                              </h2>
                            }
                            {tag.color == 'blue' &&
                              <h2>
                                <span class="ml-2 inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">{tag.text}</span>
                              </h2>
                            }
                          </>
                        ))}
                      </div>
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-gray-500">
                      <span className="block sm:inline">
                        {plan.ram} / {plan.disk} / {plan.bw} BW
                      </span>{' '}
                      <span className="hidden sm:mx-1 sm:inline" aria-hidden="true">
                        &middot;
                      </span>{' '}
                      <span className="block sm:inline">{plan.cpustats}</span>
                    </RadioGroup.Description>
                  </span>
                </span>
                <RadioGroup.Description
                  as="span"
                  className="mt-2 flex text-sm sm:mt-0 sm:ml-4 sm:flex-col sm:text-right"
                >
                  <span className="font-medium text-gray-900">${plan.price}</span>
                  <span className="ml-1 text-gray-500 sm:ml-0">/mo</span>
                </RadioGroup.Description>
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-500' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>      
        ))}
      </div>
    </RadioGroup>



    <div class="rounded-md bg-blue-50 p-4 mt-4">
<div class="flex">
<div class="flex-shrink-0">

 <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
   <path fill-rule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
 </svg>
</div>
<div class="ml-3 flex-1 md:flex md:justify-between">
 <p class="text-sm text-blue-700">Can't find what your looking for?</p>
  <p class="mt-3 text-sm md:mt-0 md:ml-6">
   <a href="#" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
     Contact us
     <span aria-hidden="true"> &rarr;</span>
   </a>
 </p> 
</div>
</div>
</div>


          </div>
        </div>
      </div>




      {/* <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Addons</h3>
              <p className="mt-1 text-sm text-gray-600">Add ons for your server.</p>
            </div>
            
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">




          <RadioGroup value={selectedManagement} onChange={setSelectedManagement}>
      <RadioGroup.Label className="text-base font-medium text-gray-900">Management</RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        {management.map((mailingList) => (
          <RadioGroup.Option
            key={mailingList.id}
            value={mailingList}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {mailingList.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                    {mailingList.features.map((feature) => (
                      <>
                        {feature.text}
                        <br></br>
                      </>
                    ))}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                      
                      {mailingList.price == 0 ? 'Included': '$ ' + mailingList.price}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-500' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>



          </div>
        </div>
      </div> */}





      <div class="hidden sm:block mt-10 mb-10" aria-hidden="true"><div class="py-5"><div class="border-t border-gray-200"></div></div></div>


      <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Server Overview</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Details for your server in {location.name}.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Plan</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.name}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">CPU</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.cpus} ({plan.cpustats})</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Ram</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.ram}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Storage</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.disk}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Bandwidth</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.bw}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Location</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{location.name}</dd>
          </div>
          <button
        type="submit"
        className="mr-5 mb-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Deploy
      </button>
        </dl>
        
      </div>
    </div>



      </div>
      </form>
    </>
  )
}
