/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, MapPinIcon, UsersIcon, CurrencyDollarIcon, ComputerDesktopIcon, GlobeAltIcon, CpuChipIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef } from 'react'
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';
import Moment from 'react-moment';


const axios = require('axios').default;
const cookies = new Cookies();


export default function Operations() {
  const ref = useRef(null)

 const [Services, setServices] = useState([]);

 useEffect(() =>{ 
  ref.current.continuousStart()
  axios.post("http://localhost:8000/v1/operations", {
    apikey: cookies.get('token'),
    api: "getOperations",
  })
  .then((response) => {
    setServices(response.data)
   ref.current.complete()
  });

    
}, [])


  return (
   <div>
     <LoadingBar color='#e11d48' ref={ref} />
         <div className="sm:flex sm:items-center mb-5">
       <div className="sm:flex-auto">
         <h1 className="text-xl font-semibold text-gray-900">Operations</h1>
         <p className="mt-2 text-sm text-gray-700">
           List of all your Operations
         </p>
       </div>
       <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
         <a
           href="/deploy/operation"
           className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
         >
           Deploy Operation
         </a>
       </div>
     </div>



     <div>
      <div className="-mx-4 mt-8 sm:-mx-0 ring-1 ring-gray-200">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-slate-500 sm:pl-4">
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-medium text-slate-500 lg:table-cell"
              >
                Created
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-medium text-slate-500 sm:table-cell"
              >
                Started
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-medium text-slate-500">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {Services.map((service) => (
              <tr key={service.uuid}>
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-4">
                <a href={"/operation/"+ service?.uuid}>{service.imageName}</a>
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only">Title</dt>
                    <dd className="mt-1 truncate text-gray-700">{service.title}</dd>
                    <dt className="sr-only sm:hidden">Email</dt>
                    <dd className="mt-1 truncate text-gray-500 sm:hidden">{service.email}</dd>
                  </dl>
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell"><Moment fromNow>{service?.Created}</Moment></td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"><Moment fromNow>{service?.State?.StartedAt}</Moment></td>
                <td className="px-3 py-4 text-sm text-gray-500">{service?.State?.Status.charAt(0).toUpperCase() + service?.State?.Status.slice(1)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

    </div>
  )
}
