import { useState, useEffect, Fragment, useRef } from 'react';
import Cookies from 'universal-cookie';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Listbox, Transition } from '@headlessui/react'
import LoadingBar from 'react-top-loading-bar'
const axios = require('axios').default;
const cookies = new Cookies();
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function NBNAppointment(props) {
 const ref = useRef(null)
const [allavailableTimeSlots, setavailableTimeSlots] = useState([])
const [selectedSlot, setSelectedSlot] = useState([])

const [userName, setuserName] = useState('')
const [userEmail, setuserEmail] = useState('')
const [userPhone, setuserPhone] = useState('')
const [serviceUUID, setserviceUUID] = useState('')


const [specialConditions, setspecialConditions] = useState('')
const [techNotes, settechNotes] = useState('')



const [inductionRequirementsBusiness, setinductionRequirementsBusiness] = useState('')
const [operationalHoursBusiness, setoperationalHoursBusiness] = useState('')
const [accessInstructionsBusiness, setaccessInstructionsBusiness] = useState('')
const [hazardsInformationBusiness, sethazardsInformationBusiness] = useState('')



const [accreditationBusiness, setaccreditationBusiness] = useState([
{
 name: "None",
 selected: 0,
},
{
 name: "Unknown",
 selected: 0,
},
{
 name: "Current White Card",
 selected: 0,
},
{
 name: "Elevated Work Platform Rescue",
 selected: 0,
},
{
 name: "Recognised CPR Training Course",
 selected: 0,
},
{
 name: "NBN Safety And Awareness",
 selected: 0,
},
{
 name: "Permit",
 selected: 0,
},
{
 name: "Police Check Required",
 selected: 0,
},
{
 name: "Power Awareness",
 selected: 0,
},
{
 name: "Remove non friable Asbestos",
 selected: 0,
},
{
 name: "Security Clearance",
 selected: 0,
},
{
 name: "Site Induction Essential",
 selected: 0,
},
{
 name: "Site Access Clearance",
 selected: 0,
},
{
 name: "Supervised Asbestos Removal",
 selected: 0,
},
{
 name: "Working At Heights",
 selected: 0,
},
{
 name: "Working With Children",
 selected: 0,
},
])
const [siteConsiderationsBusiness, setsiteConsiderationsBusiness] = useState([
{
 name: "Heritage",
 selected: 0,
},
{
 name: "Environmental",
 selected: 0,
},
{
 name: "Cultural",
 selected: 0,
},
])


function SubmitData() {
  ref.current.continuousStart()
  let accreditations = ''
  let siteConsiderations = ''
  accreditationBusiness.filter(data => data.selected == 1).map(filteredData => (

    accreditations = accreditations + filteredData.name + ', '

  ))
  siteConsiderationsBusiness.filter(data => data.selected == 1).map(filteredData => (

    siteConsiderations = siteConsiderations + filteredData.name + ', '

  ))
  axios.post("http://api.local.kowal.gg:8000/v1/nbnapi", {
    apikey: cookies.get('token'),
    api: "appointment",
    action: "bookAppointment",
    userName: userName,
    userPhone: userPhone,
    userEmail: userEmail,
    serviceUUID: serviceUUID,
    appointmentStart: selectedSlot.startTime,
    appointmentEnd: selectedSlot.endTime,
    appointmentSlotType: selectedSlot.appointmentSlotType,
    serviceUUID: serviceUUID,
    specialConditions: specialConditions,
    notes: techNotes,
    inductionRequirements: inductionRequirementsBusiness,
    operationalHours: operationalHoursBusiness,
    accessInstructions: accessInstructionsBusiness,
    accreditationRequirements: accreditations,
    siteConsiderations: siteConsiderations,
    hazardsInformation: hazardsInformationBusiness,
  })
  .then((response) => {
   ref.current.complete()
  });
}


function updateAccreditation(objectid){
let prevJSON = accreditationBusiness
prevJSON[objectid]["selected"] = 1;
let newArray = prevJSON;

setaccreditationBusiness(newArray.slice(0))
}

function updateSiteConsiderations(objectid){
let prevJSON = siteConsiderationsBusiness
prevJSON[objectid]["selected"] = 1;
let newArray = prevJSON;

setsiteConsiderationsBusiness(newArray.slice(0))
}



const [isBusiness, setisBusiness] = useState(0) // for business options



console.log(selectedSlot)
 useEffect(() =>{ 
  availableTimeSlots()
 }, [])

 function availableTimeSlots(){
  ref.current.continuousStart()
 axios.post("http://api.local.kowal.gg:8000/v1/nbnapi", {
   apikey: cookies.get('token'),
   api: "appointment",
   action: "availableTimeSlots",
   serviceUUID: props.serviceUUID,
 })
 .then((response) => {
  setisBusiness(response.data.business)
  setavailableTimeSlots(response.data.appointments)
  setSelectedSlot(response.data.appointments[0])
  setuserName(response.data.userDetails.name)
  setuserEmail(response.data.userDetails.email)
  setuserPhone(response.data.userDetails.phone)
  setserviceUUID(response.data.serviceUUID)
  ref.current.complete()
 });
}
 return (
  <>

        <LoadingBar color='#e11d48' ref={ref} />
          <div className={"divide-y divide-gray-200 overflow-show rounded-lg bg-white shadow " + (allavailableTimeSlots.length <= 0 ? 'loading-comp' : '')}>
            <div className="px-4 py-5 sm:px-6">
              <h1 className="font-medium">NBN Appointment</h1>
            </div>

              <div className="px-4 py-5 sm:p-6">

<div className="grid grid-cols-2 gap-3">
               <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
               <div className="flex justify-between">
                 <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                   Full Name
                 </label>
                 <span className="text-xs text-red-600" id="email-optional">
                   Required
                 </span>
                </div>
                 <input
                   type="text"
                   name="name"
                   id="name"
                   value={userName}
                   onChange={(e) => setuserName(e.target.value)}
                   className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                   placeholder="John Doe"
                 />
               </div>
               <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                <div className="flex justify-between">
                  <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                    Phone Number
                  </label>
                  <span className="text-xs text-red-600" id="email-optional">
                    Required
                  </span>
                </div>
                 <input
                   type="text"
                   name="phone"
                   id="phone"
                   value={userPhone}
                   onChange={(e) => setuserPhone(e.target.value)}
                   className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                   placeholder="6141234567"
                 />
               </div>
               <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
               <div className="flex justify-between">
                  <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                    Email
                  </label>
                  <span className="text-xs text-red-600" id="email-optional">
                    Required
                  </span>
                </div>
                 <input
                   type="email"
                   name="email"
                   id="email"
                   value={userEmail}
                   onChange={(e) => setuserEmail(e.target.value)}
                   className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                   placeholder="Awesome@SwiftFiber.com.au"
                 />
               </div>
               <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
               <div className="flex justify-between">
                  <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                    Service ID
                  </label>
                  <span className="text-xs text-red-600" id="email-optional">
                    Required
                  </span>
                </div>
                 <input
                   type="text"
                   name="serviceuuid"
                   id="serviceuuid"
                   value={serviceUUID}
                   className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                   placeholder="Loading..."
                   disabled
                 />
               </div>
</div>
<div className="relative mb-10 mt-10">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-start">
        <span className="bg-white pr-2 text-sm text-gray-500">Appointment Details</span>
      </div>

</div>
               <Listbox value={selectedSlot} onChange={setSelectedSlot}>
       {({ open }) => (
         <>
           <div className="flex justify-between">
             <Listbox.Label className="block text-sm font-medium text-gray-700">Appointment Time</Listbox.Label>
             <span className="text-sm text-red-600" id="email-optional">
               Required
             </span>
           </div>
           <div className="relative mt-1">
             <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
               <span className="inline-flex w-full truncate">
                 <span className="truncate">{selectedSlot?.startTimeFormated} to {selectedSlot?.endTimeFormated}</span>
                 <span className="ml-2 truncate text-gray-500">{selectedSlot?.date}</span>
               </span>
               <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                 <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
               </span>
             </Listbox.Button>

             <Transition
               show={open}
               as={Fragment}
               leave="transition ease-in duration-100"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"
             >
               <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                 {allavailableTimeSlots?.map((appointment) => (
                   <Listbox.Option
                     key={appointment.startTime + appointment.endTime + appointment.date}
                     className={({ active }) =>
                       classNames(
                         active ? 'text-white bg-indigo-600' : 'text-gray-900',
                         'relative cursor-default select-none py-2 pl-3 pr-9'
                       )
                     }
                     value={appointment}
                   >
                     {({ selected, active }) => (
                       <>
                         <div className="flex">
                           <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                           {appointment?.startTimeFormated} to {appointment?.endTimeFormated}
                           </span>
                           <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                           {appointment?.date}
                           </span>
                         </div>

                         {selected ? (
                           <span
                             className={classNames(
                               active ? 'text-white' : 'text-indigo-600',
                               'absolute inset-y-0 right-0 flex items-center pr-4'
                             )}
                           >
                             <CheckIcon className="h-5 w-5" aria-hidden="true" />
                           </span>
                         ) : null}
                       </>
                     )}
                   </Listbox.Option>
                 ))}
               </Listbox.Options>
             </Transition>
           </div>
         </>
       )}
               </Listbox>




<div className="relative mb-10 mt-10">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-start">
        <span className="bg-white pr-2 text-sm text-gray-500">Site Details</span>
      </div>
</div>




<div className="grid grid-cols-2 gapx--3 gap-y-8">
               <div className="col-span-2">
                <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                <div className="flex justify-between">
                  <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                  Special Conditions
                  </label>
                  <span className="text-xs text-gray-500" id="email-optional">
                   Optional
                  </span>
                </div>
                  <input
                    type="text"
                    name="specialConditions"
                    id="specialConditions"
                    value={specialConditions}
                    onChange={(e) => setspecialConditions(e.target.value)}
                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                    placeholder="Site requires access card."
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500" id="email-description">
                Special conditions the technician needs to be aware of.
                </p>
               </div>
               <div className="col-span-2">
                <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                <div className="flex justify-between">
                  <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                  Technician Notes
                  </label>
                  <span className="text-xs text-gray-500" id="email-optional">
                   Optional
                  </span>
                </div>
                  <input
                    type="text"
                    name="specialConditions"
                    id="specialConditions"
                    value={techNotes}
                    onChange={(e) => settechNotes(e.target.value)}
                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                    placeholder="Install next to Power Meter."
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500" id="email-description">
                 Any additional information for the technician.
                </p>
               </div>
               {isBusiness == 1 &&
               <>
                <div className="col-span-2">
                 <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                 <div className="flex justify-between">
                   <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                   Induction Requirements
                   </label>
                   <span className="text-xs text-red-600" id="email-optional">
                    Required
                   </span>
                 </div>
                   <input
                     type="text"
                     name="specialConditions"
                     id="specialConditions"
                     value={inductionRequirementsBusiness}
                     onChange={(e) => setinductionRequirementsBusiness(e.target.value)}
                     className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                     placeholder="Site induction is needed to access site"
                   />
                 </div>
                 <p className="mt-2 text-sm text-gray-500" id="email-description">
                  List any requirements needed to access the site otherwise type "No".
                 </p>
                </div>
                <div className="col-span-2">
                 <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                 <div className="flex justify-between">
                   <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                   Operational Hours
                   </label>
                   <span className="text-xs text-red-600" id="email-optional">
                    Required
                   </span>
                 </div>
                   <input
                     type="text"
                     name="specialConditions"
                     id="specialConditions"
                     value={operationalHoursBusiness}
                     onChange={(e) => setoperationalHoursBusiness(e.target.value)}
                     className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                     placeholder="8am to 4pm"
                   />
                 </div>
                 <p className="mt-2 text-sm text-gray-500" id="email-description">
                  What time does your business operate.
                 </p>
                </div>
                <div className="col-span-2">
                 <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                 <div className="flex justify-between">
                   <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                   Access Instructions
                   </label>
                   <span className="text-xs text-red-600" id="email-optional">
                    Required
                   </span>
                 </div>
                   <input
                     type="text"
                     name="specialConditions"
                     id="specialConditions"
                     value={accessInstructionsBusiness}
                     onChange={(e) => setaccessInstructionsBusiness(e.target.value)}
                     className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                     placeholder="Please see reception for swipe card"
                   />
                 </div>
                 <p className="mt-2 text-sm text-gray-500" id="email-description">
                  Instructions for how the technician can access the site.
                 </p>
                </div>
                <div className="col-span-2">
                 <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                 <div className="flex justify-between">
                   <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                    Hazards
                   </label>
                   <span className="text-xs text-gray-500" id="email-optional">
                    Optional
                   </span>
                 </div>
                   <input
                     type="text"
                     name="specialConditions"
                     id="specialConditions"
                     value={hazardsInformationBusiness}
                     onChange={(e) => sethazardsInformationBusiness(e.target.value)}
                     className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                     placeholder="High voltage lines on site."
                   />
                 </div>
                 <p className="mt-2 text-sm text-gray-500" id="email-description">
                  Any Hazards that the technician needs to be aware of.
                 </p>
                </div>
               </>
               }  
</div>
{isBusiness == 1 &&
<>
 <div className="mt-8">
            <div className="flex justify-between">
              <span className="block text-sm font-medium text-gray-700">Accreditation Requirements</span>
              <span className="text-sm text-gray-500" id="email-optional">
                Optional
              </span>
            </div>
 <fieldset className="space-y-5">
       <legend className="sr-only">Accreditation Requirements</legend>
        <div className="grid grid-cols-2 gap-3">



         {accreditationBusiness?.map((accreditation, accreditationIdx) => (
          <div className="relative flex items-start" key={accreditation.name}>
            <div className="flex h-5 items-center">
              <input
                id="accreditationRequirements"
                aria-describedby="offers-description"
                name="accreditationRequirements"
                type="checkbox"
                checked={accreditation.selected}
                onChange={(e) => updateAccreditation(accreditationIdx)}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
              {accreditation.name}
              </label>
              <span id="offers-description" className="text-gray-500">
                <span className="sr-only"></span>
              </span>
            </div>
          </div>
         ))}



        </div>
       </fieldset>
 </div>
 <div className="mt-8">
            <div className="flex justify-between">
              <span className="block text-sm font-medium text-gray-700">Site Considerations</span>
              <span className="text-sm text-gray-500" id="email-optional">
                Optional
              </span>
            </div>
 <fieldset className="space-y-5">
       <legend className="sr-only">Accreditation Requirements</legend>
        <div className="grid grid-cols-2 gap-3">



         {siteConsiderationsBusiness?.map((consideration, considerationIdx) => (
          <div className="relative flex items-start" key={consideration.name}>
            <div className="flex h-5 items-center">
              <input
                id="accreditationRequirements"
                aria-describedby="offers-description"
                name="accreditationRequirements"
                type="checkbox"
                checked={consideration.selected}
                onChange={(e) => updateSiteConsiderations(considerationIdx)}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="offers" className="font-medium text-gray-700">
              {consideration.name}
              </label>
              <span id="offers-description" className="text-gray-500">
                <span className="sr-only"></span>
              </span>
            </div>
          </div>
         ))}



        </div>
       </fieldset>
 </div>
</>
}


               { (userName != "" && userPhone != "" && userEmail != "" && serviceUUID != "") ? 
               (isBusiness == 1 && inductionRequirementsBusiness != "" && operationalHoursBusiness != "" && accessInstructionsBusiness != "") ? 
                <div className="flex justify-end">
                 <button
                 type="button"
                 onClick={(e) => SubmitData()}
                 className="mt-10 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
               >
                 Schedule Appointment
               </button>
               </div>

                 : "" 
                 : "" }

              </div>
              
          </div>

  </>
 );
}