/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, MapPinIcon, UsersIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef } from 'react'
import Cookies from 'universal-cookie';
import LoadingBar from 'react-top-loading-bar'
const axios = require('axios').default;
const SmsSettings = [
  { id: 0, title: 'Anytime' },
  { id: 1, title: '8AM - 9PM' },
  { id: 2, title: 'Never' },
]

const cookies = new Cookies();

export default function Me() {
 const ref = useRef(null);
 var apikey = cookies.get('token')
 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [address, setAddress] = useState('');
 const [smsSetting, setsmsSetting] = useState(100);

 useEffect(() =>{ 
  updateuser()
 }, [])

 function updateuser(){
 ref.current.continuousStart()

 axios.post("https://api.cloud.swiftfiber.com.au/v1/me", {
   apikey: cookies.get('token'),
 })
 .then((response) => {
   setName(response?.data?.stripe?.name)
   setEmail(response?.data?.email)
   setPhone(response?.data?.stripe?.phone)
   setAddress(response?.data?.stripe?.address?.line1 + " " + response?.data?.stripe?.address?.city + " " + response?.data?.stripe?.address?.postal_code + " " + response?.data?.stripe?.address?.state + " " + response?.data?.stripe?.address?.country)
   setsmsSetting(response?.data?.smsSetting)
   ref.current.complete()
 });
}



  return (
<>
<LoadingBar color='#e11d48' ref={ref} />



      <div>
      <form action="https://api.cloud.swiftfiber.com.au/v1/me" method="POST">
        <input value="updateDetails" id="action" name="action" hidden></input>
        <input value={apikey} id="apikey" name="apikey" hidden></input>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
              <p className="mt-1 text-sm text-gray-600">Details about you to help with personalization and KYC.</p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">

             <div className={"overflow-hidden shadow sm:rounded-md " + (email <= 1 ? 'loading-comp' : '')}>
                <div className="bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                        Full name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="full-name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>



     <div className="col-span-6 sm:col-span-4">
      <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
        Phone number
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
          +
        </span>
        <input
          type="text"
          name="phone"
          id="phone"
          value={phone}
          onChange={e => setPhone(e.target.value)}
          className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="61456789876"
        />
      </div>
    </div>



                    <div className="col-span-6">
                      <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                        Address
                      </label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        autoComplete="address"
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>



                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            
          </div>
        </div>

        <div className="py-16"><div className="border-t border-gray-200"></div></div>


        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
              <p className="mt-1 text-sm text-gray-600">Notification settings.</p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">

              <div className={"overflow-hidden shadow sm:rounded-md " + (email <= 1 ? 'loading-comp' : '')}>
                <div className="bg-white px-4 py-5 sm:p-6">
                    <div>
                      <label className="text-base font-medium text-gray-900">SMS Notifications</label>
                      <p className="text-sm leading-5 text-gray-500">When would you like to receive SMS messages?</p>
                      <fieldset className="mt-4">
                        <legend className="sr-only">SMS settings</legend>
                        <div className="space-y-4">
                          {smsSetting != 100 && SmsSettings.map((notificationMethod) => (
                            <div key={notificationMethod.id} className="flex items-center">
                              <input
                                id={notificationMethod.id}
                                name="smsSetting"
                                type="radio"
                                defaultChecked={notificationMethod.id == smsSetting}
                                value={notificationMethod.id}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label htmlFor={notificationMethod.id} className="ml-3 block text-sm font-medium text-gray-700">
                                {notificationMethod.title}
                              </label>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>



                  
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Save
                  </button>
                </div>
              </div>

          </div>
        </div>
        </form>
      </div>

      {/* <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div> */}

   
    </>
  )
}
