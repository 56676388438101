import { Fragment, useState, useEffect, useRef} from 'react'
import { Dialog, Transition, RadioGroup  } from '@headlessui/react'
import {ChevronUpDownIcon, MagnifyingGlassIcon, FingerPrintIcon, ChevronRightIcon, EnvelopeIcon, ExclamationTriangleIcon  } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/outline'

import Cookies from 'universal-cookie';
import LoadingBar from 'react-top-loading-bar'
const axios = require('axios').default;

const locationjson = [
  { id: 1, name: 'Melbourne', online: true },
  { id: 2, name: 'Sydney', online: false },
]



const osjson = [
  { id: 812, name: 'Ubuntu 18.04' },
  { id: 889, name: 'Ubuntu 19.04' },
  { id: 909, name: 'Ubuntu 20.04' },
  { id: 870, name: 'Centos 7.6' },
]









function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const cookies = new Cookies();




 












export default function Example() {
  const [apidetails, setapidetails] = useState()
  const [addresslist, setaddresslist] = useState([])
  const [plan, setPlan] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [openauthority, setOpenauthority] = useState(false)

  const [ataName, setAtaName] = useState('')
  const [ataEmail, setAtaEmail] = useState('')
  const [ataAddress, setAtaAddress] = useState('')
  const [ataPreProvider, setAtaPreProvide] = useState('')
  const [ataPreProviderID, setAtaPreProvideID] = useState('')


  const [ataSigned, setAtaSigned] = useState(false)



  const ref = useRef(null);
  var apikey = cookies.get('token')




  function submitATA(){
    ref.current.continuousStart()
    let array = {"fullName":ataName,"email":ataEmail,"billingAddress":ataAddress,"previousProvider":ataPreProvider,"previousProviderId":ataPreProviderID}
    axios.post("https://api.cloud.swiftfiber.com.au/v1/me", {
      action: "submitContract",
      data: array,
      type: "AuthorityToAct",
      apikey: apikey
    })
    .then((response) => {
      setOpenauthority(false)
      setAtaSigned(true)
      ref.current.complete()
   
    });
  }




  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {

      ref.current.continuousStart()
      axios.post("https://api.cloud.swiftfiber.com.au/v1/nbnapi", {
        api: "addressSearch",
        address: searchTerm,
      })
      .then((response) => {
        setaddresslist(response.data.responseData)
        ref.current.complete()
     
      });


    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])





function getaddressdetails(locid){
  ref.current.continuousStart()
  axios.post("https://api.cloud.swiftfiber.com.au/v1/nbnapi", {
    api: "normalSQ",
    locid: locid,
  })
  .then((response) => {
    setapidetails(response.data)
    setPlan(response.data.plans[0])
    ref.current.complete()
 
  });
}




let nbnAppointmentRequired = [3,6,9,13,24,34]

  if(apidetails == null) {return (
    <>
<LoadingBar color='#e11d48' ref={ref} />
<div>
      <label htmlFor="address" className="block text-sm font-medium text-gray-700">
        
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          autoFocus
          type="text"
          name="address"
          id="address"
          className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Address"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    </div>


    <div className="overflow-hidden bg-white shadow sm:rounded-md mt-10">
      <ul role="list" className="divide-y divide-gray-200">
        {addresslist?.map((application) => (
          <li key={application.id}>
            <a className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="truncate text-sm font-medium text-indigo-600">{application.formattedAddress}</p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <FingerPrintIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <span className="truncate">{application.id}</span>
                      </p>
                    </div>

                  </div>
                </div>
                <div>
      <button
        type="button"
        className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={() => getaddressdetails(application.id)}
      >
        Select
      </button>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>

    </>

  );}

  return (
    <>
    <Transition.Root show={openauthority} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenauthority}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Customer Contact & Authority to Act 
                    </Dialog.Title>
                    <div className="mt-2">
<div className="text-start">
    <div className="mt-3">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Full Name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="name"
            id="name"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="John Doe"
            value={ataName}
            onChange={(e) => setAtaName(e.target.value)}
            required
          />
        </div>
  </div>
  <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <div className="mt-1">
          <input
            type="email"
            name="email"
            id="email"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="you@example.com"
            value={ataEmail}
            onChange={(e) => setAtaEmail(e.target.value)}
            required
          />
        </div>
  </div>
  <div className="mt-3">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Billing Address
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="address"
            id="address"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="1 Swift Street"
            value={ataAddress}
            onChange={(e) => setAtaAddress(e.target.value)}
            required
          />
        </div>
  </div>
  <div className="mt-3">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Previous Provider (if applicable)
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="name"
            id="name"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={ataPreProvider}
            onChange={(e) => setAtaPreProvide(e.target.value)}
          />
        </div>
  </div>
  <div className="mt-3">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Previous Provider Service Number (if applicable)
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="name"
            id="name"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={ataPreProviderID}
            onChange={(e) => setAtaPreProvideID(e.target.value)}
          />
        </div>
  </div>

<div className="mt-6">
I give SwiftFiber the permission to activate this service and acknowledge that my
existing Broadband Service will be disconnected and termination fees or other contractual obligations may apply.
</div>
</div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    onClick={() => submitATA()}
                  >
                    Agree
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setOpenauthority(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    <LoadingBar color='#e11d48' ref={ref} />
    <form action="https://api.cloud.swiftfiber.com.au/v1/deploy" method="post">
      <div>
        <input value={apidetails.api.id} name="loc" id="loc" hidden></input>
        <input value={plan.id} name="priceid" id="priceid" hidden></input>
        <input value={apikey} name="apikey" id="apikey" hidden></input>
        <input value='nbn' name="service" id="service" hidden></input>

        {apidetails?.api?.siteRestriction?.serviceabilityStatus == 'Rejected' &&
        <div className="border-l-4 border-red-400 bg-red-50 p-4 mb-16">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <p className="font-bold text-md text-red-700">
                                NBN not available at this address
                                <br/>
                                <span className="font-medium text-red-700">
                                Contact SwiftFiber for more Information
                                </span>
                              </p>
                            </div>
                          </div>
          </div>
}
        {!nbnAppointmentRequired.includes(parseInt(apidetails.api.siteRestriction.supportingTechnology.serviceabilityClass)) &&
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mb-16">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                              <p className="font-bold text-md text-yellow-700">
                                NBN apointment required
                                <br/>
                                <span className="font-medium text-yellow-700">
                                You are able to order a service and an installation appointment can be made.
                                </span>
                              </p>
                            </div>
                          </div>
          </div>
}


        <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Address Details</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">NBN details for {apidetails.addressDetails.formattedAddress}.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Address</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{apidetails.addressDetails.formattedAddress}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">NBN Location ID</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{apidetails.api.id}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">NBN Technology</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{apidetails.api.siteRestriction.supportingTechnology.primaryAccessTechnology}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Service Class</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{apidetails.api.siteRestriction.supportingTechnology.serviceabilityClass} - {apidetails.ServiceClass}</dd>
          </div>
        </dl>
        
      </div>
    </div>






      <div className="hidden sm:block mt-10 mb-10" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Plan</h3>
              <p className="mt-1 text-sm text-gray-600">How fast your internet will be.</p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <RadioGroup value={plan} onChange={setPlan}>
      <RadioGroup.Label className="sr-only"> NBN Plan </RadioGroup.Label>
      <div className="space-y-4">
        {apidetails.plans.map((plan) => (
          <RadioGroup.Option
            key={plan.name}
            value={plan}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <RadioGroup.Label as="span" className="font-medium text-gray-900">
                      {plan.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-gray-500">
                      <span className="block sm:inline">
                        {plan.speed} Mbps
                      </span>{' '}
                      <span className="hidden sm:mx-1 sm:inline" aria-hidden="true">
                        &middot;
                      </span>{' '}
                      <span className="block sm:inline">${plan.setup} Setup</span>
                    </RadioGroup.Description>
                  </span>
                </span>
                <RadioGroup.Description
                  as="span"
                  className="mt-2 flex text-sm sm:mt-0 sm:ml-4 sm:flex-col sm:text-right"
                >
                  <span className="font-medium text-gray-900">${plan.price}</span>
                  <span className="ml-1 text-gray-500 sm:ml-0">/mo</span>
                </RadioGroup.Description>
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-500' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
          </div>
        </div>
      </div>






      <div class="hidden sm:block mt-10 mb-10" aria-hidden="true"><div class="py-5"><div class="border-t border-gray-200"></div></div></div>


      <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Service Overview</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Details for your NBN service at {apidetails.addressDetails.formattedAddress}.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Address</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{apidetails.addressDetails.formattedAddress}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Plan</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.name}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Speed</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.speed} Mbps</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Setup</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${plan.setup}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Month Cost</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${plan.price}</dd>
          </div>
{ataSigned == true &&
      <button
        type="submit"
        className="mr-5 mb-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Deploy
      </button>
}
{ataSigned == false &&
      <button
        onClick={() => setOpenauthority(true)}
        type="button"
        className="mr-5 mb-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Next
      </button>
}
        </dl>
        
      </div>
    </div>



      </div>
      </form>
    </>
  )
}