import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import Loading from "../components/loading"
const axios = require('axios').default;

export default function Initialauth() {
  const cookies = new Cookies();
  let { token } = useParams();



  axios.post("https://api.cloud.swiftfiber.com.au/auth/otp", {
    otp: token,
  })
  .then((response) => {
    cookies.set('token', response.data.key, { path: '/' });

    axios.post("https://api.cloud.swiftfiber.com.au/v1/me", {
      apikey: response.data.key,
    })
    .then((response) => {
      cookies.set('user', response.data, { path: '/' });
      if(response.data.email != null){
        window.location.href = '/';
      }
    });
  });






  return (
    <div className="px-10">
    <Loading classNametop="text-4xl font-semibold" classNamebottom="text-xl font-normal mt-3"/>
    </div>
  )
}
