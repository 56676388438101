import { Fragment, useState, useEffect, useRef} from 'react'
import { Listbox, Transition, RadioGroup  } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon, CheckCircleIcon , ChevronRightIcon, EnvelopeIcon  } from '@heroicons/react/20/solid'
import Cookies from 'universal-cookie';
import LoadingBar from 'react-top-loading-bar'
const axios = require('axios').default;

const plans = [
  { id: 1, title: 'Basic', features:[
      {text:"Fast, Easy-to-use DNS"},
      {text:"Unmetered DDoS Protection"},
      {text:"Free SSL"},
      {text:"CDN (Soon)"},
    ], price: 0 },
]








function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const cookies = new Cookies();




 












export default function Example() {
  const [domaindata, setdomaindata] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const ref = useRef(null);
  var apikey = cookies.get('token')


  const [selectedPlan, setSelectedPlan] = useState(plans[0]);



  function searchDomain(){
  ref.current.continuousStart()
  axios.get("https://api.cloud.swiftfiber.com.au/v1/deploy?service=domain&apikey="+ apikey + "&domain=" + searchTerm, {
  })
  .then((response) => {
    setdomaindata(response.data)
    ref.current.complete()
 
  });
}











  if(domaindata.available != 1) {return (
    <>
<LoadingBar color='#e11d48' ref={ref} />
<div>
      <label htmlFor="address" className="block text-sm font-medium text-gray-700">
        
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          autoFocus
          type="text"
          name="domain"
          id="domain"
          className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Domain"
        />
      </div>
      <button
        type="button"
        onClick={(e) => searchDomain()}
        className="float-right mt-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Go
      </button>
    </div>


    {/* <div className="overflow-hidden bg-white shadow sm:rounded-md mt-10">
      <ul role="list" className="divide-y divide-gray-200">
        {addresslist.map((application) => (
          <li key={application.id}>
            <a className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex min-w-0 flex-1 items-center">
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="truncate text-sm font-medium text-indigo-600">{application.formattedAddress}</p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <FingerPrintIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <span className="truncate">{application.id}</span>
                      </p>
                    </div>

                  </div>
                </div>
                <div>
      <button
        type="button"
        className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={() => getaddressdetails(application.id)}
      >
        Select
      </button>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div> */}

    </>

  );}else{

  return (
    <>
    <LoadingBar color='#e11d48' ref={ref} />
    <form action="https://api.cloud.swiftfiber.com.au/v1/deploy" method="post">
      <div>
        <input value={domaindata.domain} name="domain" id="domain" hidden></input>
        <input value={selectedPlan.id} name="plan" id="plan" hidden></input>
        <input value={apikey} name="apikey" id="apikey" hidden></input>
        <input value='domain' name="service" id="service" hidden></input>





        <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Domain Details</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Domain details for {domaindata.domain}.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Domain</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{domaindata.domain}</dd>
          </div>
        </dl>
        
      </div>
    </div>






      <div className="hidden sm:block mt-10 mb-10" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Plan</h3>
              <p className="mt-1 text-sm text-gray-600">Protection & Features plan.</p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">


          <RadioGroup value={selectedPlan} onChange={setSelectedPlan}>


      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        {plans.map((plan) => (
          <RadioGroup.Option
            key={plan.id}
            value={plan}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {plan.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                    {plan.features.map((feature) => (
                      <>
                        {feature.text}
                        <br></br>
                      </>
                    ))}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                    {plan.price == 0 ? 'Free': '$ ' + plan.price}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-500' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>


          </div>
        </div>
      </div>






      <div class="hidden sm:block mt-10 mb-10" aria-hidden="true"><div class="py-5"><div class="border-t border-gray-200"></div></div></div>


      <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Service Overview</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Details for domain {domaindata.domain}.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Domain</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{domaindata.domain}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Plan</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{selectedPlan.title}</dd>
          </div>


          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Month Cost</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${selectedPlan.price}</dd>
          </div>
          <button
        type="submit"
        className="mr-5 mb-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Deploy
      </button>
        </dl>
        
      </div>
    </div>



      </div>
      </form>
    </>
  );}
}