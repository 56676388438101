import { Fragment, useState } from 'react'
import { Listbox, Transition, RadioGroup  } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, PaperClipIcon  } from '@heroicons/react/20/solid'
/* This example requires Tailwind CSS v2.0+ */
import { MegaphoneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Cookies from 'universal-cookie';
const locationjson = [
  { id: 1, name: 'Melbourne', online: true },
  { id: 2, name: 'Sydney', online: false },
]

const plans = [
  { id: 1, name: 'xsmall', ram: 2, cpus: 1, disk: 25, bw: 500, price: 6 },
  { id: 2, name: 'small', ram: 4, cpus: 1, disk: 55, bw: 1000, price: 10 },
  { id: 3, name: 'medium', ram: 8, cpus: 2, disk: 80, bw: 1000, price: 20 },
  { id: 4, name: 'large', ram: 8, cpus: 4, disk: 120, bw: 3000, price: 40 },
  { id: 5, name: 'xlarge', ram: 16, cpus: 6, disk: 200, bw: 4000, price: 60 },
  { id: 6, name: 'xxlarge', ram: 16, cpus: 8, disk: 300, bw: 5000, price: 80 },
]

const osjson = [
  { id: 'standard-812', name: 'Ubuntu 18.04', icon:"https://swiftportal.s3.ap-southeast-2.amazonaws.com/os-icons/ubuntu.svg" },
  { id: 'standard-909', name: 'Ubuntu 20.04', icon:"https://swiftportal.s3.ap-southeast-2.amazonaws.com/os-icons/ubuntu.svg"  },
  { id: 'standard-1017', name: 'Ubuntu 22.04', icon:"https://swiftportal.s3.ap-southeast-2.amazonaws.com/os-icons/ubuntu.svg"  },

  { id: 'standard-100001', name: 'Windows Server 2019', icon:"https://swiftportal.s3.ap-southeast-2.amazonaws.com/os-icons/windows.svg"  },

  { id: 'standard-878', name: 'Debian 10', icon:"https://swiftportal.s3.ap-southeast-2.amazonaws.com/os-icons/debian.svg"  },
  { id: 'standard-979', name: 'Debian 11', icon:"https://swiftportal.s3.ap-southeast-2.amazonaws.com/os-icons/debian.svg" },

  { id: 'standard-895', name: 'CentOS 8', icon:"https://swiftportal.s3.ap-southeast-2.amazonaws.com/os-icons/centos.svg"  },
  { id: 'standard-943', name: 'CentOS 8.2', icon:"https://swiftportal.s3.ap-southeast-2.amazonaws.com/os-icons/centos.svg"  },

  { id: 'standard-100002', name: 'Mikrotik CHR 7.10', icon:"https://swiftportal.s3.ap-southeast-2.amazonaws.com/os-icons/debian.svg"  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const cookies = new Cookies();

export default function Example() {
  const [location, setLocation] = useState(locationjson[0])
  const [plan, setPlan] = useState(plans[0])
  const [os, setOs] = useState(osjson[0])
  var apikey = cookies.get('token')
  return (
    <>
    <form action="https://api.cloud.swiftfiber.com.au/v1/deploy" method="post">
      <div>
        <input value={location.id} name="location" id="location" hidden></input>
        <input value={plan.id} name="plan" id="plan" hidden></input>
        <input value={os.id} name="os" id="os" hidden></input>
        <input value={apikey} name="apikey" id="apikey" hidden></input>
        <input value='virtualserver' name="service" id="service" hidden></input>


<div>




        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Location</h3>
              <p className="mt-1 text-sm text-gray-600">
                Where your server will be hosted.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <Listbox value={location} onChange={setLocation}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium text-gray-700">Location</Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                      <span
                        aria-label={location.online ? 'Online' : 'Offline'}
                        className={classNames(
                          location.online ? 'bg-green-400' : 'bg-gray-200',
                          'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                        )}
                      />
                      <span className="ml-3 block truncate">{location.name}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {locationjson.map((person) => (
                        <Listbox.Option
                          key={person.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={person}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    person.online ? 'bg-green-400' : 'bg-gray-200',
                                    'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                                  )}
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(location ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                >
                                  {person.name}
                                  <span className="sr-only"> is {person.online ? 'online' : 'offline'}</span>
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Plan</h3>
              <p className="mt-1 text-sm text-gray-600">How much compute your server will have.</p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <RadioGroup value={plan} onChange={setPlan}>
      <RadioGroup.Label className="sr-only"> Server size </RadioGroup.Label>
      <div className="space-y-4">
        {plans.map((plan) => (
          <RadioGroup.Option
            key={plan.name}
            value={plan}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center">
                  <span className="flex flex-col text-sm">
                    <RadioGroup.Label as="span" className="font-medium text-gray-900">
                      {plan.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-gray-500">
                      <span className="block sm:inline">
                        {plan.ram}GB / {plan.cpus} vCores
                      </span>{' '}
                      <span className="hidden sm:mx-1 sm:inline" aria-hidden="true">
                        &middot;
                      </span>{' '}
                      <span className="block sm:inline">{plan.disk}GB SSD</span>
                      <span className="hidden sm:mx-1 sm:inline" aria-hidden="true">
                        &middot;
                      </span>{' '}
                      <span className="block sm:inline">{plan.bw}GB Bandwidth</span>
                    </RadioGroup.Description>
                  </span>
                </span>
                <RadioGroup.Description
                  as="span"
                  className="mt-2 flex text-sm sm:mt-0 sm:ml-4 sm:flex-col sm:text-right"
                >
                  <span className="font-medium text-gray-900">${plan.price}</span>
                  <span className="ml-1 text-gray-500 sm:ml-0">/mo</span>
                </RadioGroup.Description>
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-500' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Operating System</h3>
              <p className="mt-1 text-sm text-gray-600">What will be installed onto your server.</p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">

          <Listbox value={os} onChange={setOs}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Operating System</Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <img src={os.icon} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />
                <span className="ml-3 block truncate">{os.name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {osjson.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img src={person.icon} alt="" className="h-5 w-5 flex-shrink-0" />
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                          >
                            {person.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>


          {/* <Listbox value={os} onChange={setOs}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">Operating System</Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
              <span className="block truncate">{os.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {osjson.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {person.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox> */}
          </div>
        </div>
      </div>


      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Details</h3>
              <p className="mt-1 text-sm text-gray-600">Details to applied to your server</p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
          <div>
      <div className="flex justify-between">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Hostname
        </label>
        <span className="text-sm text-purple-500" id="email-optional">
          Must be a valid domain
        </span>
      </div>
      <div className="mt-1">
        <input
          type="text"
          name="hostname"
          id="hostname"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="myserver.mydomain.com"
          aria-describedby="hostname-required"
        />
      </div>
    </div>
          </div>
        </div>
      </div>



      <div class="hidden sm:block mt-10 mb-10" aria-hidden="true"><div class="py-5"><div class="border-t border-gray-200"></div></div></div>


      <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Server Overview</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Details for your server in {location.name}.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Plan</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.name}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">CPU</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.cpus} vCores</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Ram</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.ram} GB</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Storage</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.disk} GB SSD</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Bandwidth</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{plan.bw} GB</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Operating System</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{os.name}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Location</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{location.name}</dd>
          </div>
          <button
        type="submit"
        className="mr-5 mb-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Deploy
      </button>
        </dl>
        
      </div>
    </div>



      </div>
      </form>
    </>
  )
}
