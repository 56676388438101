/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, MapPinIcon, UsersIcon, CurrencyDollarIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef } from 'react'
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';

const axios = require('axios').default;
const cookies = new Cookies();


export default function Dedicatedservers() {
  const ref = useRef(null)

 const [Services, setServices] = useState([]);

 useEffect(() =>{ 
  updatedata()
 }, [])

 function updatedata(){
  ref.current.continuousStart()
 axios.post("https://api.cloud.swiftfiber.com.au/v1/services", {
   apikey: cookies.get('token'),
   filter: "dedicatedserver",
 })
 .then((response) => {
  setServices(response.data)
  ref.current.complete()
 });
}


  return (
   <div>
     <LoadingBar color='#e11d48' ref={ref} />
         <div className="sm:flex sm:items-center mb-5">
       <div className="sm:flex-auto">
         <h1 className="text-xl font-semibold text-gray-900">Dedicated Servers</h1>
         <p className="mt-2 text-sm text-gray-700">
           List of all your Dedicated Servers
         </p>
       </div>
       <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
         <a
           href="/deploy/dedicatedserver"
           className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
         >
           Deploy Server
         </a>
       </div>
     </div>


     {Services?.pending > 0 &&

<div class="rounded-md bg-blue-50 p-4 mb-4">
<div class="flex">
<div class="flex-shrink-0">

 <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
   <path fill-rule="evenodd" d="M19 10.5a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zM8.25 9.75A.75.75 0 019 9h.253a1.75 1.75 0 011.709 2.13l-.46 2.066a.25.25 0 00.245.304H11a.75.75 0 010 1.5h-.253a1.75 1.75 0 01-1.709-2.13l.46-2.066a.25.25 0 00-.245-.304H9a.75.75 0 01-.75-.75zM10 7a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
 </svg>
</div>
<div class="ml-3 flex-1 md:flex md:justify-between">
 <p class="text-sm text-blue-700">{ Services?.pending } service(s) pending creation.</p>
 {/* <p class="mt-3 text-sm md:mt-0 md:ml-6">
   <a href="#" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
     Details
     <span aria-hidden="true"> &rarr;</span>
   </a>
 </p> */}
</div>
</div>
</div>
}


    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {Services?.services?.map((service) => (
          <li key={service.uuid}>
            <a href={"/sub/" + service.uuid} className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="truncate text-sm font-medium text-indigo-600">{service.server.cpu.name} ({service.server.entity.full_ip})</p>
                  <div className="ml-2 flex flex-shrink-0">
                    <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      {/* {service.vpsstats.status} */}
                      Active
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      <ComputerDesktopIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      {service.plan.nickname}
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      {service.server.group.name}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <p>
                    {service.plan.nickname} - ${service.plan.amount / 100}/{service.plan.interval}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
    </div>
  )
}
