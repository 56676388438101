/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import Moment from 'react-moment';
import {
  LinkIcon,
  ShareIcon,
  InboxArrowDownIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  MapPinIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
  CheckIcon
} from '@heroicons/react/20/solid'
import { XMarkIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { Menu, Transition, Dialog, Listbox } from '@headlessui/react'
import toast, { Toaster } from 'react-hot-toast';
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';
const axios = require('axios').default;
const cookies = new Cookies();
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function ViewOperation(data) {
  const ref = useRef(null)
  let { uuid } = useParams();
  const [Services, setServices] = useState([]);
  const [Logs, setLogs] = useState("");
  const [stats, setstats] = useState([]);
 const containerid = "348b220dba74b7d208e49015e8a775a21558df386dc6bb66945c14fd0af6b8a9"
  useEffect(() =>{ 
    ref.current.continuousStart()
    axios.post("http://localhost:8000/v1/operations", {
      apikey: cookies.get('token'),
      uuid: uuid,
      api: "getOperation",
    })
    .then((response) => {
      setServices(response.data)
     ref.current.complete()
    });

setInterval(() => {
  axios.post("http://localhost:8000/v1/operations", {
    apikey: cookies.get('token'),
    uuid: uuid,
    api: "action",
    action: "logs",
  })
  .then((response) => {
    setLogs(response.data)
  });
}, 5000);
      
  }, [])

  function powerAction(type) {
    var promise = axios.post("http://localhost:8000/v1/operations", {
      apikey: cookies.get('token'),
      uuid: uuid,
      api: "action",
      action: type,
    })
    .then((response) => {
    });
    toast.promise(promise, {
      loading: type + "ing operation",
      success: "operation " + type + "ed",
      error: 'An error has occurred while '+ type +'ing the operation',
    });
  }


const [currenttab, setTab] = useState(0)




  const tabs = [
    { name: 'Details', tabnum: 0,},
    { name: 'Logs', tabnum: 1,},
  ]




  




 



  return (
<div>
<Toaster position="bottom-right" />








<LoadingBar color='#e11d48' ref={ref} />
<div className="lg:flex lg:items-center lg:justify-between mb-20">
<div className="min-w-0 flex-1">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Operation
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  {containerid}
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {Services?.imageName}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              Melbourne
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            $0/m
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">


        <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          Actions
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <span
                onClick={() => powerAction("start")}
                  className={classNames(
                    active ? 'bg-green-600 text-white' : 'text-gray-700 bg-white',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  Start
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <span
                onClick={() => powerAction("pull")}
                  className={classNames(
                    active ? 'bg-green-600 text-white' : 'text-gray-700 bg-white',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  Re Deploy
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <span
                onClick={() => powerAction("stop")}
                  className={classNames(
                    active ? 'bg-red-600 text-white' : 'text-gray-700 bg-white',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  Stop
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <span
                onClick={() => powerAction("kill")}
                  className={classNames(
                    active ? 'bg-red-600 text-white' : 'text-gray-700 bg-white',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  Kill
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <span
                onClick={() => powerAction("destroy")}
                  className={classNames(
                    active ? 'bg-red-600 text-white' : 'text-gray-700 bg-white',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  Destroy
                </span>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>


        {/* Dropdown */}

      </div>
    </div>



    <div class="mb-20">
    <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={currenttab}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <a
              key={tab.name}
              onClick={() => setTab(tab.tabnum)}
              className={classNames(
                tab.tabnum == currenttab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
              )}
              aria-current={tab.tabnum == currenttab ? 'page' : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.tabnum == currenttab ? 'bg-indigo-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </a>
          ))}
        </nav>
      </div>
    </div>


  {currenttab == 0 &&
    <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Operation Details</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Operation information</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">State</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{Services?.State?.Status}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Created</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"><Moment fromNow>{Services?.Created}</Moment></dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Started</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"><Moment fromNow>{Services?.State?.StartedAt}</Moment></dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Ports</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {Services?.Ports != undefined && Object.keys(Services?.Ports).map((port, portIdx) => (
              <>
                {port}
                {/* {Services?.Ports[port]?.map((port, portIdx) => (
                  <>
                  <br/>
                    <span className="ml-2">-- {port?.HostIp}:{port?.HostPort}</span>
                  </>
            ))} */}

            <br/>
           
              </>
            ))}

            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Domains</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {Services?.domains?.map((domain, domainIdx) => (
              <>
              <a target="_blank" href={"http://" + domain}>{domain}</a>
              </>
            ))}
              
            </dd>
          </div>
          {(Services?.State?.Error != "") &&
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Error</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{Services?.State?.Error}</dd>
          </div>
          }
          {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Stopped</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"><Moment fromNow>{Services?.State?.FinishedAt}</Moment></dd>
          </div> */}

        </dl>
      </div>
    </div>
}
  {currenttab == 1 &&
<div className="bg-slate-900 text-slate-400 h-[50vh] p-3 overflow-auto">
  <pre>{Logs || "[SwiftFiber] | Loading Logs...."}</pre>
</div>
}




    </div>
  )
}
