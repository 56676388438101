/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, MapPinIcon, UsersIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Cookies from 'universal-cookie';
import LoadingBar from 'react-top-loading-bar'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'
const axios = require('axios').default;


const cookies = new Cookies();

export default function Teams() {
 const ref = useRef(null);
 var apikey = cookies.get('token')
 const [teams, setTeams] = useState([]);
 const [openEditTeam, setOpenEditTeam] = useState(false)
 const [EditTeamName, setEditTeamName] = useState('')
 const [EditTeamMembers, setEditTeamMembers] = useState([])

 useEffect(() =>{ 
  updateuser()
 }, [])

 function updateuser(){
 ref.current.continuousStart()
 axios.post("http://local.kowal.gg:8000/v1/me", {
   apikey: cookies.get('token'),
   action: 'showTeams',
 })
 .then((response) => {
  setTeams(response?.data)
   ref.current.complete()
 });
}



  return (
<>
<LoadingBar color='#e11d48' ref={ref} />








<Transition.Root show={openEditTeam} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenEditTeam}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">{EditTeamName}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpenEditTeam(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Edit {EditTeamName}
                        </p>
                      </div>
                    </div>
                    <div className="relative flex-1 py-6 px-4 sm:px-6">

                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Team Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => setEditTeamName(e.target.value)}
                            value={EditTeamName}
                          />
                        </div>
                      </div>

                      <div className="mt-8">
                        <span className="block text-sm font-medium text-gray-700">Members</span>
                      <ul role="list" className="divide-y divide-gray-20">
                        {EditTeamMembers?.map((person) => (
                          <li key={person.email} className="flex py-4">
                            <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />
                            <div className="ml-3">
                              <p className="text-sm font-medium text-gray-900">{person.email}</p>
                              <p className="text-sm text-gray-500">{person.email}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                      </div>

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>








<div className="sm:flex sm:items-center mb-5">
       <div className="sm:flex-auto">
         <h1 className="text-xl font-semibold text-gray-900">Teams</h1>
         <p className="mt-2 text-sm text-gray-700">
           List of what teams you are in
         </p>
       </div>
       <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
         <a
           href="/deploy/domain"
           className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
         >
           Create / Join Team
         </a>
       </div>
     </div>


<div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {teams?.map((position) => (
          <li key={position.uuid}>
            <a onClick={() => {setOpenEditTeam(true); setEditTeamName(position.name); setEditTeamMembers(position.members) } } className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex text-sm">
                      <p className="truncate font-medium text-indigo-600">{position.name}</p>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex items-center text-sm text-gray-500">
                        <p>
                        {position.uuid}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <div className="flex -space-x-1 overflow-hidden">
                      {position?.members?.map((applicant) => (
                        <img
                          key={applicant.email}
                          className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                          src={applicant.imageUrl}
                          alt={applicant.email}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="ml-5 flex-shrink-0">
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>

   
    </>
  )
}
