/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, MapPinIcon, UsersIcon, CurrencyDollarIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef, Fragment } from 'react'
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import toast, { Toaster } from 'react-hot-toast';
import Tickets from '../components/Tickets';

const axios = require('axios').default;
const cookies = new Cookies();


export default function Support() {
  const ref = useRef(null)

 const [Services, setServices] = useState([]);
 const [Docs, setDocs] = useState([]);
 const [newticketopen, setnewticketopen] = useState(false)
 const [newticketsubject, setnewticketsubject] = useState('')
 const [newticketmessage, setnewticketmessage] = useState('')
 useEffect(() =>{ 
  updatedata()
 }, [])

 function updatedata(){



 axios.post("https://api.cloud.swiftfiber.com.au/v1/support", {
   apikey: cookies.get('token'),
   view: "docs",
 })
 .then((response) => {
  setDocs(response.data)
 });
}

 function createTicket(){
  ref.current.continuousStart()
  setnewticketopen(false);
 let promise = axios.post("https://api.cloud.swiftfiber.com.au/v1/support", {
   apikey: cookies.get('token'),
   view: "tickets",
   action: "createticket",
   subject: newticketsubject,
   message: newticketmessage,
 })
 .then((response) => {
  setServices(response.data)
  ref.current.complete()
 });
 toast.promise(promise, {
  loading: 'Creating Ticket',
  success: 'Ticket Created',
  error: 'An error has occurred while creating the ticket',
});
}

const regex = /(<([^>]+)>)/ig;

  return (
   <div>
     <Toaster position="bottom-right" />
     <LoadingBar color='#e11d48' ref={ref} />






     <Transition.Root show={newticketopen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setnewticketopen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">New Ticket</Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setnewticketopen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Create a new ticket
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label htmlFor="project-name" className="block text-sm font-medium text-gray-900">
                                Subject
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="subject"
                                  id="subject"
                                  value={newticketsubject}
                                  onChange={(e) => setnewticketsubject(e.target.value)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                Message
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="message"
                                  name="message"
                                  rows={4}
                                  value={newticketmessage}
                                  onChange={(e) => setnewticketmessage(e.target.value)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  defaultValue={''}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setnewticketopen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={createTicket}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Create
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>






     
         <div className="sm:flex sm:items-center mb-5">
       <div className="sm:flex-auto">
         <h1 className="text-xl font-semibold text-gray-900">Support</h1>
         <p className="mt-2 text-sm text-gray-700">
           Get support and answers for all SwiftFiber related services
         </p>
       </div>
       <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
         <button
           onClick={() => setnewticketopen(true)}
           className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
         >
           New Ticket
         </button>
       </div>
     </div>





<Tickets/>






    <h1 className="text-xl font-semibold text-gray-900 mt-10 mb-5">Docs</h1>
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {Docs?.docs?.map((doc) => (
        <li key={doc.uuid} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <a href={"/support/doc/" + doc.uuid}><h3 className="truncate text-sm font-medium text-gray-900">{doc.title}</h3></a>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">{doc.body.replace(regex, '')}</p>
            </div>
            <img className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" src={doc.author_imageurl} alt="" />
          </div>
          <div>
          </div>
        </li>
      ))}
    </ul>


    </div>
  )
}
