/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState  } from 'react'
import { Dialog, Transition, Switch } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Cookies from 'universal-cookie';
import {
  ChevronRightIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/20/solid'
import LoadingBar from 'react-top-loading-bar'
const axios = require('axios').default;
const cookies = new Cookies();
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function Viewdomain(serviceData) {
  const ref = useRef(null)

  const [data, setData] = useState(serviceData.serviceData)

  var dataServed = (data.access.reduce((a,v) =>  a = a + parseInt(v.body_bytes_sent) , 0 ));

  const stats = [
    { name: 'Records', stat: data?.records?.length == undefined ? 0 : data?.records?.length },
    { name: 'Data Served', stat: Math.ceil(dataServed/1000000) + ' MB' },
  ]



  const [openSlideOver, setOpenSlideOver] = useState(false)
  const [openNewRecordSlideOver, setOpenNewRecordSlideOver] = useState(false)
 


  const [editDomain, seteditDomain] = useState('')
  const [editType, seteditType] = useState('')
  const [editContent, seteditContent] = useState('')
  const [editProxy, seteditProxy] = useState(0)
  const [editRecordUUID, seteditRecordUUID] = useState('')

  const [newDomain, setnewDomain] = useState('')
  const [newType, setnewType] = useState('A')
  const [newProxy, setnewProxy] = useState(0)
  const [newContent, setnewContent] = useState('')

  function actionDeleteRecord() {
    ref.current.continuousStart()
    axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + data.uuid, {
      apikey: cookies.get('token'),
      action: "deleteRecord",
      recorduuid: editRecordUUID
    })
    .then((response) => {
      setData(response.data);
      setOpenSlideOver(false);
     ref.current.complete()
    });
  }
  function actionUpdateRecord() {
    ref.current.continuousStart()
    axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + data.uuid, {
      apikey: cookies.get('token'),
      action: "updateRecord",
      domain: editDomain,
      type: editType,
      content: editContent,
      recorduuid: editRecordUUID,
      proxy: editProxy
    })
    .then((response) => {

      setData(response.data);
      setOpenSlideOver(false);
     ref.current.complete()
    });
  }

  function actionNewRecord() {
    ref.current.continuousStart()
    axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + data.uuid, {
      apikey: cookies.get('token'),
      action: "newRecord",
      domain: newDomain,
      type: newType,
      content: newContent,
      proxy: newProxy
    })
    .then((response) => {
      setData(response.data);
      setOpenNewRecordSlideOver(false);
     ref.current.complete()
    });
  }

  return (
<div>







<LoadingBar color='#e11d48' ref={ref} />



{/* edit record slideover */}
<Transition.Root show={openSlideOver} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenSlideOver}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">Edit {editDomain + '.' + data.domain}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpenSlideOver(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Edit {editDomain}'s DNS details.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex-1 py-6 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0 py-6 px-4 sm:px-6">
                        



                      <div>
                            <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                              Domain
                            </label>
                            <div className="relative mt-1 rounded-md shadow-sm">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={editDomain}
                                onChange={(e) => seteditDomain(e.target.value)}
                                aria-describedby="price-currency"
                              />
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <span className="text-gray-500 sm:text-sm" id="price-currency">
                                {'.' + data.domain}
                                </span>
                              </div>
                            </div>
                              <p className="mt-2 text-sm text-gray-500" id="email-description">
                              Use @ for root
                              </p>
                          </div>





                          <label htmlFor="type" className="block text-sm font-medium text-gray-700 mt-5">
                            Type
                          </label>
                          <select
                            id="type"
                            name="type"
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            defaultValue={editType}
                            onChange={(e) => seteditType(e.target.value)}
                          >
                            <option value="A">A</option>
                            <option value="CNAME">CNAME</option>
                            <option value="PTR">PTR</option>
                          </select>







                          <div className="flex justify-between mt-5">
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Content
                          </label>
                          <span className="text-sm text-gray-500" id="email-optional">
                            Required
                          </span>
                        </div>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="content"
                            id="content"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={editContent}
                            onChange={(e) => seteditContent(e.target.value)}
                            aria-describedby="email-optional"
                          />
                        </div>
{editType == 'A' &&
<>
    <div className="relative py-3">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white pr-2 text-sm text-gray-500">Proxy</span>
      </div>
    </div>

    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
          Proxy
          <span className="ml-1 inline-flex items-center rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">
            Beta
          </span>
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          Proxy {editDomain + '.' + data.domain} through SF.
        </Switch.Description>
      </span>
      <Switch
        checked={editProxy}
        onChange={seteditProxy}
        className={classNames(
          editProxy ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            editProxy ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>



      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
</>
          }
                        <button
                          type="button"
                          onClick={actionUpdateRecord}
                          className="mt-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={actionDeleteRecord}
                          className="mt-5 inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          Delete
                        </button>





                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>




{/* new record slideover */}
<Transition.Root show={openNewRecordSlideOver} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenNewRecordSlideOver}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">New Record</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpenNewRecordSlideOver(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          New record details.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex-1 py-6 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0 py-6 px-4 sm:px-6">
                        




                          <div>
                            <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                              Domain
                            </label>
                            <div className="relative mt-1 rounded-md shadow-sm">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className="block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="subdomain"
                                onChange={(e) => setnewDomain(e.target.value)}
                                aria-describedby="price-currency"
                              />
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <span className="text-gray-500 sm:text-sm" id="price-currency">
                                {'.' + data.domain}
                                </span>
                              </div>
                            </div>
                            <p className="mt-2 text-sm text-gray-500" id="email-description">
                              Use @ for root
                              </p>
                          </div>


                          <label htmlFor="type" className="block text-sm font-medium text-gray-700 mt-5">
                            Type
                          </label>
                          <select
                            id="type"
                            name="type"
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            defaultValue={newType}
                            onChange={(e) => setnewType(e.target.value)}
                          >
                            <option value="A">A</option>
                            <option value="CNAME">CNAME</option>
                            <option value="PTR">PTR</option>
                          </select>







                          <div className="flex justify-between mt-5">
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Content
                          </label>
                          <span className="text-sm text-gray-500" id="email-optional">
                            Required
                          </span>
                        </div>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="content"
                            id="content"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => setnewContent(e.target.value)}
                            aria-describedby="email-optional"
                          />
                        </div>



{newType == 'A' &&
<>

    <div className="relative py-3">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white pr-2 text-sm text-gray-500">Proxy</span>
      </div>
    </div>

    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
          Proxy
          <span className="ml-1 inline-flex items-center rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">
            Beta
          </span>
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          Proxy {newDomain + '.' + data.domain} through SF.
        </Switch.Description>
      </span>
      <Switch
        checked={newProxy}
        onChange={setnewProxy}
        className={classNames(
          newProxy ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            newProxy ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>


      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

</>
}
                        <button
                          type="button"
                          onClick={actionNewRecord}
                          className="mt-5 float-right inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Create
                        </button>





                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>







<div className="lg:flex lg:items-center lg:justify-between mb-20">
<div className="min-w-0 flex-1">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="/services/domains" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Domain
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  {data.uuid}
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {data.domain}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {data.plan.nickname} - {data.plan.amount / 100}/{data.plan.interval}
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">


        {/* <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          Actions
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <span
                  onClick={actionStart}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Start Server
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <span
                  onClick={actionStop}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Stop Server
                </span>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu> */}


        {/* Dropdown */}
        {/* <Menu as="div" className="relative ml-3 sm:hidden">
          <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            More
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                  >
                    Edit
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                  >
                    View
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu> */}
      </div>
    </div>



    <div class="mb-20">
      <h3 className="text-lg font-medium leading-6 text-gray-900">Statistics</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>




    <div className="px-4 sm:px-0 lg:px-0 mb-20">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">DNS Records</h1>
          <p className="mt-2 text-sm text-gray-700">
            List of all DNS records associated with {data.domain}
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => setOpenNewRecordSlideOver(true)}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Record
          </button>
        </div>
      </div>
      <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Type
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Content
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Proxy Status
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {data?.records?.map((record) => (
              <tr key={record.uuid}>
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                  {record.name}
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only">Type</dt>
                    <dd className="mt-1 truncate text-gray-700">{record.type}</dd>
                    <dt className="sr-only sm:hidden">Value</dt>
                    <dd className="mt-1 truncate text-gray-500 sm:hidden">{record.content}</dd>
                  </dl>
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{record.type}</td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{record.content}</td>
                <td className={record.proxy == 0 ? 'hidden px-3 py-4 text-sm text-gray-500 sm:table-cell' : 'hidden px-3 py-4 text-sm sm:table-cell text-indigo-600'}>{record.proxy == 0 ? 'DNS Only' : 'Proxied'}</td>
                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <a onClick={() => {setOpenSlideOver(true); seteditDomain(record.name); seteditType(record.type); seteditContent(record.content); seteditRecordUUID(record.uuid); seteditProxy(record.proxy)} } className="cursor-pointer text-indigo-600 hover:text-indigo-900">
                    Edit<span className="sr-only"></span>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>




    <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Service Details</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Service information</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Domain</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.domain}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Name Servers</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">ns1.swiftfiber.com.au, ns2.swiftfiber.com.au</dd>
          </div>



        </dl>
      </div>
    </div>

    </div>
  )
}
