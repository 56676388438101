import { useState, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";
import ServiceAdmin from '../components/serviceAdmin';
import Viewvirtualserver from './Viewvirtualserver';
import Viewdedicatedserver from './Viewdedicatedserver';
import Viewdomain from './Viewdomain';
import Viewnbn from './Viewnbn';
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';

import Loading from "../components/loading"
const axios = require('axios').default;

const cookies = new Cookies();

let user = cookies.get('user')

export default function Showservice() {
 const ref = useRef(null);
 let { uuid } = useParams();

 const [Services, setServices] = useState([]);
 
 useEffect(() =>{ 
  updatedata()
 }, [])

 function updatedata(){
  ref.current.continuousStart()
 axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + uuid, {
   apikey: cookies.get('token'),
 })
 .then((response) => {
  setServices(response.data)
  ref.current.complete()
 });
 }


if(Services.type == "virtualserver"){
 return (
  <div>
   <LoadingBar color='#e11d48' ref={ref} />
   <Viewvirtualserver data={Services} />
   {user["staff"] == 1 && <ServiceAdmin uuid={uuid} />}
  </div>
  )
 }else if(Services.type == "dedicatedserver"){
  return (
   <div>
    <LoadingBar color='#e11d48' ref={ref} />
    <Viewdedicatedserver data={Services} />
    {user["staff"] == 1 && <ServiceAdmin uuid={uuid} />}
   </div>
   )
 }else if(Services.type == "nbn"){
  return (
   <div>
    <LoadingBar color='#e11d48' ref={ref} />
    <Viewnbn data={Services} />
    {user["staff"] == 1 && <ServiceAdmin uuid={uuid} />}
   </div>
   )
 }else if(Services.type == "domain"){
  return (
  <div>
  <LoadingBar color='#e11d48' ref={ref} />
  <Viewdomain serviceData={Services} />
  {user["staff"] == 1 && <ServiceAdmin uuid={uuid} />}
 </div>
  )
 }else{
  return (
   <div>
    <LoadingBar color='#e11d48' ref={ref} />
    <Loading classNametop="text-4xl font-semibold" classNamebottom="text-xl font-normal mt-3"/>
   </div>
   )
 }
 }