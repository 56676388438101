/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, UsersIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid'
import { useState, useEffect, useRef, Fragment } from 'react'
import { useParams } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import Cookies from 'universal-cookie';
import NBNAppointment from '../components/NBNAppointment';
import toast, { Toaster } from 'react-hot-toast';
import Moment from 'react-moment';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  BriefcaseIcon,
  PaperClipIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
  ChatBubbleLeftEllipsisIcon,
  TagIcon,
  UserCircleIcon
} from '@heroicons/react/20/solid'
import { Menu, Transition, Switch } from '@headlessui/react'
const axios = require('axios').default;
const cookies = new Cookies();

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function Ticket() {
  let { uuid } = useParams();
  const ref = useRef(null)

 const [Services, setServices] = useState([]);
 const [Replybox, setReplybox] = useState("");
 const [Replystatus, setReplystatus] = useState(4);








 useEffect(() =>{ 
  updatedata()
 }, [])

 function updatedata(){
  ref.current.continuousStart()
 axios.post("https://api.cloud.swiftfiber.com.au/v1/support", {
   apikey: cookies.get('token'),
   view: "ticket",
   uuid: uuid,
 })
 .then((response) => {
  setServices(response.data)
  ref.current.complete()
 });
}


function actionReply() {
  ref.current.continuousStart()
  let promise = axios.post("https://api.cloud.swiftfiber.com.au/v1/support", {
    apikey: cookies.get('token'),
    uuid: uuid,
    action: "reply",
    view: "ticket",
    message: Replybox,
    status: Replystatus,
  })
  .then((response) => {
  setServices(response.data)
  setReplybox('')
   ref.current.complete()
  });
  toast.promise(promise, {
    loading: 'Sending Reply',
    success: 'Reply Sent',
    error: 'An error has occurred while sending the reply',
  });
}

  return (
   <div>
     <Toaster position="bottom-right" />
     <LoadingBar color='#e11d48' ref={ref} />
     <div className="lg:flex lg:items-center lg:justify-between mb-20">
      <div className="min-w-0 flex-1">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="/support" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Ticket
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  {Services.uuid}
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {Services.subject || <Skeleton width="300px"/>}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
          {Services.status == 0 &&
                    <p className="inline-flex rounded-full bg-slate-300 px-2 text-xs font-semibold leading-5 text-slate-500">
                      Closed
                    </p>
                        }
                        {Services.status == 1 &&
                    <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      Active
                    </p>
                        }
                        {Services.status == 2 &&
                    <p className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                      Customer Reply
                    </p>
                        }
                        {Services.status == 3 &&
                    <p className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                      Waiting for customer reply
                    </p>
                        }
                        {Services.status == 4 &&
                    <p className="inline-flex rounded-full bg-slate-300 px-2 text-xs font-semibold leading-5 text-slate-900">
                      Answered
                    </p>
                        }
                        {Services.status == 5 &&
                    <p className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                      On Hold
                    </p>
                        }
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
          Last Reply:&nbsp;<Moment fromNow>{Services?.messages?.at(-1).time}</Moment>
          </div>
        </div>
      </div>

    </div>








    <div className="flow-root">
      <div className="grid auto-cols-2 grid-flow-col-dense gap-4">
        <div>
          <ul role="list" className="mt-5 mb-8">
            {Services?.messages?.map((activityItem, activityItemIdx) => (
              <li key={activityItem.id}>
                <div className="relative pb-8">
                  {activityItemIdx !== Services?.messages?.length - 1 ? (
                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                  
                      <>
                        <div className="relative">
                          <img
                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                            src={activityItem.user_imageurl}
                            alt=""
                          />
    {/* 
                          <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                            <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span> */}
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <div className="text-sm">
                              <a href="#" className="font-medium text-gray-900">
                                {activityItem.user_name}
                                {activityItem.user_staff == 1 &&
                                      <span className="ml-2 inline-flex items-center rounded-full bg-purple-100 px-2.5 py-0.5 text-xs font-medium text-purple-800">
                                      Staff
                                      </span>
                                }
                              </a>
                            </div>
                            <p className="mt-0.5 text-sm text-gray-500"><Moment fromNow>{activityItem.time}</Moment></p>
                          </div>
                          <div className="mt-2 text-sm text-gray-700">
                            <p className="whitespace-pre-wrap">{activityItem.message}</p>
                          </div>
                        </div>
                      </>
                  </div>
                </div>
              </li>
            )) || 
            <Skeleton width="300px" count="5"/>
            }

          </ul>
        </div>
        {Services?.widgets?.map((widget, widgetIdx) => (
          <>
          {widget?.type == "nbnAppointment" &&
          <>
            {widget.appointmentId == undefined &&
            <div>
              <NBNAppointment serviceUUID={widget.serviceUUID} />
            </div>
            }
          </>
          }
          </>
        ))}
        
      </div>
      <div className="w-full border-t border-gray-300 mt-12" />
      <div className="mt-12">
      <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
        Reply
      </label>
      <div className="mt-1">
        <textarea
          rows={4}
          name="comment"
          id="comment"
          value={Replybox}
          onChange={(e) => setReplybox(e.target.value)}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          defaultValue={''}
        />
      </div>
      <div>

      {Services.isStaff == 1 &&
      <>
      <label htmlFor="location" className="block text-sm font-medium text-gray-700">
        Status
      </label>
      <select
        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        defaultValue="Answered"
        onChange={(e) => setReplystatus(e.target.value)}
      >
        <option value="4">Answered</option>
        <option value="3">Waiting for customer reply</option>
        <option value="5">On Hold</option>
        <option value="0">Closed</option>
      </select>
      </>
      }
    </div>
      <button
        type="button"
        onClick={actionReply}
        className="float-right mt-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Reply
      </button>
    </div>
    </div>







    </div>
  )
}
