import { useState, useEffect, Fragment, useRef } from 'react';
import Cookies from 'universal-cookie';
import Moment from 'react-moment';
import LoadingBar from 'react-top-loading-bar'
const axios = require('axios').default;
const cookies = new Cookies();
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Tickets() {
  const [Services, setServices] = useState([]);
  const [Ticketcount, setTicketcount] = useState({});
  const [filter, setfilter] = useState(1234);
  const ref = useRef(null)

  useEffect(() =>{ 
    ref.current.continuousStart()
    axios.post("https://api.cloud.swiftfiber.com.au/v1/support", {
      apikey: cookies.get('token'),
      view: "tickets",
    })
    .then((response) => {
     setServices(response.data)
     var res = {};
     response.data.tickets.forEach(function(v) {
       res[v.status] = (res[v.status] || 0) + 1;
     })
    
    setTicketcount(res)

     ref.current.complete()
    });
   }, [])


 return (
  <>

        <LoadingBar color='#e11d48' ref={ref} />
<div className={(Services.length == 0 ? 'loading-comp' : '')}>
{(Services.tickets != undefined && Services?.tickets.length != 0) &&
<div className="grid grid-cols-4 gap-4 mb-2">
  <div className="col-span-3"></div>
        <div>

      <select
        onChange={e => setfilter(parseInt(e.target.value))}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        defaultValue="All"
      >
        <option value="1234">Open</option>
        <option value="1">Active</option>
        <option value="2">Customer Reply</option>
        <option value="3">Waiting for customer reply</option>
        <option value="4">Answered</option>
        <option value="5">On Hold</option>
        <option value="0">Closed</option>
      </select>
        </div>
</div>
}
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {Services?.tickets?.map((service) => (
          <>
        {((service.status === filter) || (filter === 1234 && service.status != 0)) &&
          <li key={service.uuid}>
            <a href={"/support/ticket/" + service.uuid} className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="truncate text-sm font-medium text-indigo-600">{service.subject}</p>
                  <div className="ml-2 flex flex-shrink-0">

                    {service.status == 0 &&
                    <p className="inline-flex rounded-full bg-slate-300 px-2 text-xs font-semibold leading-5 text-slate-500">
                      Closed
                    </p>
                        }
                        {service.status == 1 &&
                    <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      Active
                    </p>
                        }
                        {service.status == 2 &&
                    <p className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                      Customer Reply
                    </p>
                        }
                        {service.status == 3 &&
                    <p className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                      Waiting for customer reply
                    </p>
                        }
                        {service.status == 4 &&
                    <p className="inline-flex rounded-full bg-slate-300 px-2 text-xs font-semibold leading-5 text-slate-900">
                      Answered
                    </p>
                        }
                        {service.status == 5 &&
                    <p className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                      On Hold
                    </p>
                        }


                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                    {service.uuid}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <p>
                    Last Reply:&nbsp;<Moment fromNow>{service?.messages?.at(-1).time}</Moment>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </li>
          
        }
        </>
        ))}
      </ul>
    </div>
<center className="mt-2">{Ticketcount[0] >= 0 && <span onClick={() => setfilter(0)} className="text-sm font-medium text-indigo-600 cursor-pointer">View {Ticketcount[0]} closed tickets</span>}</center>
</div>
  </>
 );
}