/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import toast, { Toaster } from 'react-hot-toast';
import {
  BriefcaseIcon,
  PaperClipIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  LinkIcon,
  MapPinIcon,
  PencilIcon,
} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import LoadingBar from 'react-top-loading-bar'
const axios = require('axios').default;
const cookies = new Cookies();
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function Viewvirtualserver(data) {
  const ref = useRef(null)
  const stats = [
    { name: 'CPU', stat:  data.data.vpsstats.used_cpu},
    { name: 'Ram', stat: Math.ceil(((data.data.vpsstats.used_ram)/(data.data.vpsdetails.ram)) * 100) },
    { name: 'Storage', stat: Math.ceil(((data.data.vpsstats.used_disk)/(data.data.vpsdetails.space)) * 100) },
    { name: 'Bandwidth', stat: Math.ceil(((data.data.vpsstats.used_bandwidth)/(data.data.vpsstats.bandwidth)) * 100) },
  ]

  const [passwordShown, setPasswordShown] = useState(false);

  function actionStop() {
    ref.current.continuousStart()
    let promise = axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + data.data.uuid, {
      apikey: cookies.get('token'),
      action: "stop",
    })
    .then((response) => {
     ref.current.complete()
    });
    toast.promise(promise, {
      loading: 'Requesting server stop',
      success: 'Server stop requested',
      error: 'An error has occurred while requesting the server to stop',
    });
  }
  function actionStart() {
    ref.current.continuousStart()
    let promise = axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + data.data.uuid, {
      apikey: cookies.get('token'),
      action: "start",
    })
    .then((response) => {
     ref.current.complete()
    });
    toast.promise(promise, {
      loading: 'Requesting server start',
      success: 'Server start requested',
      error: 'An error has occurred while requesting the server to start',
    });
  }
  function actionRestart() {
    ref.current.continuousStart()
    let promise = axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + data.data.uuid, {
      apikey: cookies.get('token'),
      action: "restart",
    })
    .then((response) => {
     ref.current.complete()
    });
    toast.promise(promise, {
      loading: 'Requesting server restart',
      success: 'Server restart requested',
      error: 'An error has occurred while requesting the server to restart',
    });
  }
  function actionRebuild() {
    ref.current.continuousStart()
    let promise = axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + data.data.uuid, {
      apikey: cookies.get('token'),
      action: "rebuild",
    })
    .then((response) => {
     ref.current.complete()
    });
    toast.promise(promise, {
      loading: 'Requesting server rebuild',
      success: 'Server rebuild requested',
      error: 'An error has occurred while requesting the server to rebuild',
    });
  }




  return (
<div>
<Toaster position="bottom-right" />
<LoadingBar color='#e11d48' ref={ref} />
<div className="lg:flex lg:items-center lg:justify-between mb-20">
      <div className="min-w-0 flex-1">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="/services/virtualservers" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Virtual Server
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  {data.data.uuid}
                </a>
              </div>
            </li>
          </ol>
        </nav>
        <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {data.data.vpsdetails.hostname}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {data.data.location}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {data.data.plan.nickname} - ${data.data.plan.amount / 100}/{data.data.plan.interval}
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">


        <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          Actions
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <span
                  onClick={actionStart}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Start Server
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <span
                  onClick={actionStop}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Stop Server
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <span
                  onClick={actionRestart}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Restart Server
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <span
                  onClick={actionRebuild}
                  className={classNames(
                    active ? 'bg-red-600 text-white' : 'text-gray-700 bg-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Rebuild Server
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a href={"/cancel/" + data.data.uuid}>
                <span
                  className={classNames(
                    active ? 'bg-red-600 text-white' : 'text-gray-700 bg-white',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Cancel Server
                </span>
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>


  
      </div>
    </div>



    <div class="mb-20">
      <h3 className="text-lg font-medium leading-6 text-gray-900">Current Usage</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 border sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}%</dd>
          </div>
        ))}
      </dl>
    </div>


    {/* <div class="mb-20">
      <h3 className="text-lg font-medium leading-6 text-gray-900">Health</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 border sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}%</dd>
          </div>
        ))}
      </dl>
    </div> */}



    <div className="overflow-hidden bg-white border sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Server Details</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Server information</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Hostname</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.vpsdetails.hostname}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">IP Address</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {Object.entries(data.data.vpsdetails.ips).map((key, value) => (
              key[1]
            ))}
              
              </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">vCores</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.vpsdetails.cores}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Ram</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.vpsdetails.ram} MB</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Storage</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.vpsdetails.space} GB</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Bandwidth</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.data.vpsstats.bandwidth} GB</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Username</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <input
                  type={passwordShown ? "text" : "password"}
                  value="root"
                    className="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <button
                  onClick={setPasswordShown}
                  type="button"
                  className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  <span>Show</span>
                </button>
              </div>
              
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Password</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              
              <div className="mt-1 flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <input
                  type={passwordShown ? "text" : "password"}
                  value={data.data.password}
                    className="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <button
                  onClick={setPasswordShown}
                  type="button"
                  className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  <span>Show</span>
                </button>
              </div>
              
            </dd>
          </div>

        </dl>
      </div>
    </div>

    </div>
  )
}
