import { useState, useEffect, Fragment, useRef } from 'react';
import Cookies from 'universal-cookie';
import Moment from 'react-moment';
import LoadingBar from 'react-top-loading-bar'
const axios = require('axios').default;
const cookies = new Cookies();

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function ServiceAdmin(props) {
  const [Services, setServices] = useState({});
  const ref = useRef(null)

  useEffect(() =>{ 
    axios.post("https://api.cloud.swiftfiber.com.au/v1/service?serviceuuid=" + props.uuid, {
      apikey: cookies.get('token'),
      staff: "getStaffData"
    })
    .then((response) => {
     setServices(response.data)
    });
   }, [])


 return (
  <>
    <div className="overflow-hidden rounded-lg bg-white border mt-8">
      <div className="px-4 py-5 sm:p-6">
        <div>
        <span className="font-bold mr-3">Stripe ID:</span><a className="underline text-blue-500" href={"https://dashboard.stripe.com/subscriptions/" + Services.stripeId} target="_blank">{Services.stripeId}</a>
        </div>
        <div>
        <span className="font-bold mr-3">Customer:</span><a className="underline text-blue-500" href={"https://dashboard.stripe.com/customers/" + Services.customer} target="_blank">{Services.customer}</a>
        </div>
      </div>
    </div>
  </>
 );
}